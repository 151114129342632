import React from 'react';
import c from './AddictionPage.module.css'
import { Helmet } from 'react-helmet';

class AddictionPage extends React.Component{
    componentDidUpdate() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    render() {
        

        return(
            <div className = {c.container}>
               <Helmet>
               <meta name="description" content="Addiction is a complex condition, a brain disease that is manifested by compulsive substance use despite harmful consequence. Learn more at sthreekendra.com"/>
               <meta name="keywords" content="addiction, addict, addicting, grambling, drugs, alcohol, narcotics, medical, assisted, substance, use, disorder, disease, help, symptoms, treatment, signs, warnings, mental, health, organization, illness, care, stories, events, news, expert, qa, frequently, asked, questions, faq"/>
               </Helmet>
                <div className = {c.one} id = 'what-is-addiction home'>
                    <header className = {c.header}>
                                <div className = {c.title}>What Is Addiction?</div>
                        </header>
                </div>
                <div className = {c.one_container}>
                        <div className = {c.content_one}>
                            <div className = {c.what_is_addiction}>
                                <div className = {c.question_bold}>What Is the Medical Definition of Addiction?</div>
                                <p>An addiction is a chronic dysfunction of the brain system that involves reward, motivation, and memory. It’s about the way your body craves a substance or behavior, especially if it causes a compulsive or obsessive pursuit of “reward” and lack of concern over consequences.</p>
                            </div>
                            <div className = {c.someone}>
                            Someone experiencing an addiction will:
                            </div>
                            <ul>
                                <li>be unable stay away from the substance or stop the addictive behavior</li>
                                <li>display a lack of self-control</li>
                                <li>have an increased desire for the substance or behavior</li>
                                <li>dismiss how their behavior may be causing problems</li>
                                <li>lack an emotional response</li>
                            </ul>
                           
                        </div>
                        <div className = {c.one_two}>
                            <h3>Addiction</h3>
                            <ul>
                                <li><a href = '#what-is-addiction'><i className="fas fa-long-arrow-alt-right"></i> What Is Addiction?</a> </li>
                                <li><a href = '#signs-of-addiction'><i className="fas fa-long-arrow-alt-right"></i>  Signs of Addiction</a></li>
                                <li><a href = '#addiction-test'><i className="fas fa-long-arrow-alt-right"></i>  Drug and Addiction Test</a></li>
                                <li><a href = '#stigma-of-addiction'><i className="fas fa-long-arrow-alt-right"></i>  What Is the Stigma of Addiction?</a></li>
                                <li><a href = '#dealing-with-addiction'><i className="fas fa-long-arrow-alt-right"></i>Dealing With Addiction</a></li>
                                <li><a href = '#addiction-prevention'><i className="fas fa-long-arrow-alt-right"></i> Addiction Prevention</a></li>
                            </ul>
                        </div>
                </div>
                <div className = {c.two} id = 'signs-of-addiction'>
                        <div className = {c.question_bold}>Signs of Addiction</div>
                        <div className = {c.dummy}>
                        How to Tell if You or a Loved One Needs Help Addiction to alcohol or other drugs leads to negative consequences in pretty much every area of life: social, emotional, financial, legal, physical health, employment, family, and school. Knowing the signs and symptoms of addiction can prompt earlier intervention and, ultimately, better outcomes. 
                        </div>
                        <div className = {c.ul_div}>
                           <div className = {c.ul_one}>
                            <div className = {c.question}>Behavioral Signs and Symptoms</div>
                                <ul>
                                    <li>Always uses substance to intoxication</li>    
                                    <li>Uses substance at inappropriate times such as before driving, at work, or at school</li>    
                                    <li>Misses work or school</li>    
                                    <li>Damages relationships</li>    
                                    <li>Poor performance at school or work</li>    
                                    <li>Steals or borrows money from work, home, or friends</li>    
                                    <li>Secretive, defensive behavior about activities and possessions</li>    
                                    <li>Unusual mood changes</li>    
                                    <li>Abrupt temper outbursts</li>    
                                    <li>Changes in eating or sleeping habits</li>    
                                    <li>Changes in peer group or social group</li>    
                                    <li>Loss of interest in usual activities, pastimes, and hobbies</li>    
                                    <li>Aggressive or physical behavior</li>    
                                    <li>Money or valuables missing from home</li>    
                                    <li>Traveling to locations outside of normal range</li>    
                                    <li>    Depressed or anxious</li>
                                        <li>    Deterioration of personal appearance or hygiene</li>
                                </ul>
                           </div>
                            <div className = {c.ul_two}>
                                    <div className = {c.question}>
                                    Physical Signs and Symptoms
                                    </div>
                                    <ul>
                                        
                                        <li>Rapid weight gain or loss</li>
                                        <li> Slow or staggering walk</li>
                                        <li>Inability to sleep or awake at unusual times</li>
                                        <li> Unexplained bruises or marks</li>
                                        <li>       Glazed or red eyes</li>
                                        <li>    Pupils larger or smaller than usual, blank stare</li>
                                        <li>    Cold, sweaty palms or shaking hands</li>
                                        <li>    Puffy face, blushing or paleness</li>
                                        <li>    Extreme hyperactivity; excessive talkativeness</li>
                                        <li>    Runny nose, hacking cough</li>
                                        <li>    Needle marks on lower arm, leg or bottom of feet</li>
                                        <li>    Nausea, vomiting, or excessive sweating</li>
                                        <li>    Unusual nose bleeds</li>
                                        <li>    Unexplained breakout of acne/rash</li>
                                        <li>    Unusual odors</li>
                                        <li>    Low or no energy</li>
                                       
                                    </ul>
                            </div>
                        </div>
                </div>
                <div className = {c.three} id = 'addiction-test'>
                    <div className = {c.question_bold}>Drug and Addiction Test</div>
                    <div className = {c.question}>Wondering if Your Alcohol or Drug Use Is Becoming a Problem?</div>
                    <p className = {c.marginleft}>If you’re asking yourself this question, you probably already realize you could be headed for trouble. Still, it can be confusing to understand how serious your situation might be and whether you actually have an addiction to alcohol or drugs.
                    </p>
                    <p className = {c.marginleft}>Some people become addicted quickly, using drugs more frequently or heavily and moving on to harder drugs in a short period of time. For others, alcoholism or other drug addiction can be a very slow process, developing into increasingly heavy drinking or drug use over many years. In fact, people around you might not be aware of how serious your situation has become if you’ve been hiding your drinking or other drug use—which is typical behavior for someone with addiction.
                    </p>
                </div>

                <div className = {c.four} id = 'stigma-of-addiction'>
                  <div className = {c.question_bold}> What Is the Stigma of Addiction?</div> 
                  <p className = {c.marginleft}>Stigma is defined in the dictionary as “a mark of disgrace or infamy.” The stigma of addiction—the mark of disgrace or infamy associated with the disease—stems from behavioral symptoms and aspects of substance use disorder. For example, symptoms of alcohol and other drug addiction, such as impaired judgment or erratic behavior, can result in negative consequences including legal, occupational and relationship problems. Understandably, these kinds of consequences cause embarrassment and shame among those afflicted and affected. They also create stigmatized attitudes and perceptions about addiction among the wider public, a response that perpetuates and exacerbates the private shame associated with drug addiction.
                  </p>
                  <p className = {c.marginleft}>For generations, this combination of personal shame and public stigma has produced tremendous obstacles to addressing the problem of alcoholism and other drug addiction in America. Today, the stigma of addiction is seen as a primary barrier to effective addiction prevention, treatment and recovery efforts at the individual, family, community and societal levels. Addiction stigma prevents too many people from getting the help they need.
                 </p>
                </div>

                <div className = {c.five} id = 'dealing-with-addiction'>
                    <div className = {c.question_bold}> Dealing with Addiction</div>
                    <div className = {c.question}> Ways to Tell—and What to Do—if You're Worried That a Loved One Has Addiction</div>
                    <p className = {c.marginleft}>Addiction doesn't happen overnight. It's a gradual process. Sometimes family members don't recognize the small changes that occur daily, weekly or monthly—or how family members adapt to these changes. Here are six steps you can take to see through the chaos of addiction and find help for your family.</p>
                    <ul className = {c.four_ul}>
                        <li>Notice your own behavior.</li>
                        <li>Recognize the signs of addiction.</li>
                        <li>Stay detached, but with love.</li>
                        <li>Consider other mental health issues.</li>
                        <li>Don’t judge</li>
                        <li>Begin the conversation and keep expectations low.</li>

                    </ul>
                </div>

                <div className = {c.six} id = 'addiction-prevention'>
                    <div className = {c.question_bold}>Addiction Prevention</div>
                    <div className = {c.question}>Addiction is a Preventable, Chronic Disease</div>
                    <p className = {c.marginleft}>Addiction Hazelden Betty Ford Foundation is committed to getting out in front of addiction, with prevention efforts aimed at keeping healthy kids, teens and young adults healthy.
                    </p>
                    <p className = {c.marginleft}>AddictionAll young people deserve the chance to figure out who they are, what they care about, and how they want to contribute to the world. But those hopes and dreams can disappear when alcohol or other drugs enter the picture.
                    </p>
                    <p className = {c.marginleft}>AddictionFortunately, there are proven-effective addiction prevention approaches to help youth, families and communities address the risks and realities of early substance abuse. The most promising efforts equip young people with the knowledge, understanding and skills they need to make healthy choices. These addiction prevention efforts draw from:
                    </p>
                        <ul>
                            <li>
                            Emerging research on adolescent brain chemistry
                            </li>
                            <li>Scientific findings about risk and protective factors associated with early substance use</li>
                            <li>Accurate data on youth attitudes and behaviors related to substance use</li>
                        </ul>
                        <p className = {c.marginleft}> Parents become effective prevention agents in their own homes when they recognize the essential role they play in keeping kids drug free during childhood and adolescence.
                        </p>
                </div>
            </div>
        )
    }
}


export default AddictionPage;