import React from 'react'
import c from './Leaders.module.css'
import Slider from 'infinite-react-carousel';

class Leaders extends React.Component{
    settings = {
        arrows: false,
        arrowsBlock: false,
        autoplay: true,
        autoplaySpeed: 5000,
        duration: 500,
        dots:true
    }
    render() {
        
        return(
            <div className = {c.main}>
                <div className = {c.d_container}>
                    <header>Meet Our Team Leaders</header>
                    <div className = {c.images}>
                        <div className = {c.img}>
                        <i class="fas fa-user"></i>
                            <div className = {c.content}>
                                <p>Puttaraju C</p>
                                <span>Founder</span>
                            </div>
                        </div>

                        <div className = {c.img}>
                        <i class="far fa-user"></i>
                            {/* <img src = 'https://asanarecovery.com/wp-content/uploads/2020/01/Chrismalrs2.jpg' alt = ''/> */}
                            <div className = {c.content}>
                                <p>Geetha Raj</p>
                                <span>President</span>
                            </div>
                        </div>

                        <div className = {c.img}>
                        <i class="fas fa-user"></i>
                            <div className = {c.content}>
                                <p>Sunil Murthy</p>
                                <span>Senior Counsellor</span>
                           
                            </div>
                        </div>

                        <div className = {c.img}>
                        <i class="far fa-user"></i>
                            <div className = {c.content}>
                                <p>Amrutha Raj</p>
                                <span>Secretary</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className = {c.m_container}>
                <header>Meet Our Team Leaders</header>
                <Slider { ...this.settings }>
                
                <div className = {c.img}>
                            {/* <img src = 'https://asanarecovery.com/wp-content/uploads/2020/01/Chrismalrs2.jpg' alt = ''/> */}
                            <i class="fas fa-user"></i>
                            <div className = {c.content}>
                                <p>Puttaraju C</p>
                                <span>Founder</span>
                            </div>
                        </div>

                        <div className = {c.img}>
                        <i class="fas fa-user"></i>
                            <div className = {c.content}>
                                <p>Geetha Raj</p>
                                <span>President</span>
                            </div>
                        </div>

                        <div className = {c.img}>
                        <i class="fas fa-user"></i>
                            <div className = {c.content}>
                                <p>Sunil Murthy</p>
                                <span>Senior Counsellor</span>
                            </div>
                        </div>

                        <div className = {c.img}>
                        <i class="fas fa-user"></i>
                            <div className = {c.content}>
                                <p>Amrutha Raj</p>
                                <span>Secretary</span>
                            </div>
                        </div>
                </Slider>
                </div>
            </div>
        )
    }
}

export default Leaders;