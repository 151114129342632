import React from 'react'
import c from './Patients.module.css'
import Slider from 'infinite-react-carousel'
import Img from './female.png'
class Patients extends React.Component{
    settings = {
        arrows: false,
        arrowsBlock: false,
        autoplay: true,
        autoplaySpeed: 10000,
        duration: 500,
        dots:true
    }
    render() {

        return(
            <div className = {c.main}>
                <div className  = {c.d_container}> 
                    <header>See What Our Patients Say...</header>
                    <div className = {c.patients}>

                        <div className = {c.patient}>
                            <div className = {c.content}>
                                <p>“I came here a broken Women, I left ready to face the world and am working a program and trying to the best of my ability to repay my stay here, and to the wonderful staff of counselors, therapists and chef and all the staff that helped me on my journey to sober living and a better life.”</p>
                            </div>
                            <div className = {c.stars}>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            </div>
                            <div className = {c.patient_info}>
                                <img src = {Img} alt = 'patient'></img>
                                <div className = {c.name}>
                                    <p>anonymous</p>
                                    <span>Patient</span>
                                </div>
                            </div>
                        </div>
                  


                    <div className = {c.patient}>
                            <div className = {c.content}>
                                <p>“Sthree Kendra changed my life and set me on the path to recovery. I am now 93 days sober. This is the longest I have been sober in years I am so grateful for the employees and owners. They were incredibly compassionate and even dealt with my occasional outburst. They showed me the right path!!”</p>
                            </div>
                            <div className = {c.stars}>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            </div>
                            <div className = {c.patient_info}>
                                <img src = {Img} alt = 'patient'></img>
                                <div className = {c.name}>
                                    <p>anonymous</p>
                                    <span>Patient</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className = {c.patient}>
                            <div className = {c.content}>
                                <p>“The people here are amazing!! The center really focuses on recovery and helps you with the tools you need to fully recover from your disease. If your considering recovery centers this is a great choice. The employees do a great job of making you feel welcome and not judged!!”</p>
                            </div>
                            <div className = {c.stars}>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            </div>
                            <div className = {c.patient_info}>
                                <img src = {Img} alt = 'patient'></img>
                                <div className = {c.name}>
                                    <p>anonymous</p>
                                    <span>Patient</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className  = {c.m_container}>
                <header>See What Our Patients Say...</header>
                <div className = {c.m_patients}>
                <Slider {...this.settings}>
                        <div className = {c.m_patient}>
                            <div className = {c.m_content}>
                                <p>“I came here a broken Women, I left ready to face the world and am working a program and trying to the best of my ability to repay my stay here, and to the wonderful staff of counselors, therapists and chef and all the staff that helped me on my journey to sober living and a better life.”</p>
                            </div>
                            <div className = {c.m_stars}>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                            <div className = {c.m_patient_info}>
                            <img src = {Img} alt = 'patient'></img>
                                <div className = {c.name}>
                                    <p>anonymous</p>
                                    <span>Patient</span>
                                </div>
                            </div>
                        </div>
                  


                    <div className = {c.m_patient}>
                        <div className = {c.m_content}>
                                <p>“Sthree Kendra changed my life and set me on the path to recovery. I am now 93 days sober. This is the longest I have been sober in years I am so grateful for the employees and owners. They were incredibly compassionate and even dealt with my occasional outburst. They showed me the right path!!”</p>
                        </div>
                            <div className = {c.m_stars}>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                            <div className = {c.m_patient_info}>
                            <img src = {Img} alt = 'patient'></img>
                                <div className = {c.name}>
                                    <p>anonymous</p>
                                    <span>Patient</span>
                                </div>
                            </div>
                    </div>
                        
                        <div className = {c.m_patient}>
                            <div className = {c.m_content}>
                                <p>“The people here are amazing!! The center really focuses on recovery and helps you with the tools you need to fully recover from your disease. If your considering recovery centers this is a great choice. The employees do a great job of making you feel welcome and not judged!!”</p>
                            </div>
                            <div className = {c.m_stars}>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                            </div>
                            <div className = {c.m_patient_info}>
                            <img src = {Img} alt = 'patient'></img>
                                <div className = {c.name}>
                                    <p>anonymous</p>
                                    <span>Patient</span>
                                </div>
                            </div>
                        </div>
                </Slider>
                </div>
                </div>
            </div>
        )
    }
}


export default Patients;