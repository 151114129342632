import React from 'react'
import c from './Psypage.module.css'

class Psypage extends React.Component{
    componentDidUpdate() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    render() {
        return (
            <div className = {c.main} id = 'home'>
                    <header>
                        <h3>Common Mental Health Issues Affecting Women's</h3>
                    </header>
                    <div className = {c.container}>
                                <div className = {c.one} id = 'phobia'>
                                    <div className = {c.title}>
                                        <h2>PHOBIA</h2>
                                    </div>
                                    <p>A phobia is an excessive and irrational fear reaction. If you have a phobia, you may experience a deep sense of dread or panic when you encounter the source of your fear. The fear can be of a certain place, situation, or object. Unlike general anxiety disorders, a phobia is usually connected to something specific.</p>
                                    <h3>Symptoms of phobia</h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> pounding or racing heart</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> shortness of breath</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> nausea</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> trembling or shaking</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> a choking sensation</li>
                                    </ul>
                                </div>

                                <div className = {c.two} id = 'depression'>
                                    <div className = {c.title}>
                                        <h2>DEPRESSION </h2>
                                    </div>
                                    <p>Depression is classified as a mood disorder. It may be described as feelings of sadness, loss, or anger that interfere with a person’s everyday activities.</p>
                                    <p>People experience depression in different ways. It may interfere with your daily work, resulting in lost time and lower productivity. It can also influence relationships and some chronic health conditions.</p>
                                    <h3>Symptoms of depression</h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Lost interest</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Increased fatigue</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Sleep problems</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Anxiety</li>
                                        
                                    </ul>
                                </div>


                                <div className = {c.one} id = 'ptsd'>
                                    <div className = {c.title}>
                                        <h2>PTSD</h2>
                                    </div>
                                    <p>Post-traumatic stress disorder (PTSD) is a mental health condition that's triggered by a terrifying event — either experiencing it or witnessing it. Symptoms may include flashbacks, nightmares and severe anxiety, as well as uncontrollable thoughts about the event.</p>
                                    <p>Getting effective treatment after PTSD symptoms develop can be critical to reduce symptoms and improve function.</p>
                                    <h3>Symptoms of PTSD</h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Intrusive memories</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Avoidance</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Negative changes in thinking and mood</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Changes in physical and emotional reactions</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> a choking sensation</li>
                                    </ul>
                                </div>

                                <div className = {c.two} id ='ocd'>
                                    <div className = {c.title}>
                                        <h2>OCD</h2>
                                    </div>
                                    <p>Obsessive-compulsive disorder (OCD) is a chronic mental health condition characterized by obsessions which lead to compulsive behaviors.</p>
                                    <p>People often double check to make sure they’ve locked the front door or always wear their lucky socks on game days — simple rituals or habits that make them feel more secure.</p>
                                    <h3>Symptoms of phobia</h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Obsessions</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Compulsions</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Intrusive sexually explicit</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Fear of losing control </li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Excessive focus on religious or moral ideas.</li>
                                    </ul>
                                </div>


                                <div className = {c.one} id = 'bipolar'>
                                    <div className = {c.title}>
                                        <h2>BIPOLAR </h2>
                                    </div>
                                    <p>Bipolar disorder is a mental illness marked by extreme changes in mood from high to low, and from low to high. Highs are periods of mania, while lows are periods of depression. The changes in mood may even become mixed, so you might feel elated and depressed at the same time.</p>
                                    <p>Bipolar disorder can be hard to diagnose, but there are signs or symptoms that you can look for.</p>
                                    <h3>Signs  of bipolar</h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> feeling overly happy or “high” for long periods of time</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> having a decreased need for sleep</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> talking very fast, often with racing thoughts</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> feeling extremely restless or impulsive</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> becoming easily distracted</li>
                                    </ul>
                                </div>

                                <div className = {c.two} id = 'insomnia'>
                                    <div className = {c.title}>
                                        <h2>INSOMNIA </h2>
                                    </div>
                                    <p>Insomnia is a type of sleep disorder. Individuals with insomnia find it difficult to fall asleep, stay asleep, or both.</p>
                                    <p>People with insomnia often don’t feel refreshed when they wake up from sleeping, either. This can lead to fatigue and other symptoms.</p>
                                    <p>Insomnia is the most common of all sleep disorders, according to the American Psychiatric Association (APA).</p>
                                    <h3>Insomnia causes</h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> stress</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> an upsetting or traumatic event</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> physical pain</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> jet lag </li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> changes to your sleep habits, like sleeping in a hotel or new home</li>
                                    </ul>
                                </div>



                                <div className = {c.one} id = 'eating-disorder'>
                                    <div className = {c.title}>
                                        <h2>EATING DISORDER </h2>
                                    </div>
                                    <p>Although the term eating is in the name, eating disorders are about more than food. They’re complex mental health conditions that often require the intervention of medical and psychological experts to alter their course.</p>
                                    <p>In India alone, an estimated 20 million women and 10 million men have or have had an eating disorder at some point in their life.</p>
                                    <p>Eating disorders are a range of psychological conditions that cause unhealthy eating habits to develop. They might start with an obsession with food, body weight, or body shape.</p>
                                    <h3>Types of Eating Disorders</h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Anorexia nervosa</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Bulimia nervosa</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Binge eating disorder</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Pica</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i>Rumination disorder</li>
                                    </ul>
                                </div>

                                <div className = {c.two} id = 'schizophrenia'>
                                    <div className = {c.title}>
                                        <h2> SCHIZOPHRENIA </h2>
                                    </div>
                                    <p>Schizophrenia is a serious mental disorder in which people interpret reality abnormally. Schizophrenia may result in some combination of hallucinations, delusions, and extremely disordered thinking and behavior that impairs daily functioning, and can be disabling.</p>
                                    <p>People with schizophrenia require lifelong treatment. Early treatment may help get symptoms under control before serious complications develop and may help improve the long-term outlook.</p>

                                    <h3>Symptoms Of Schizophrenia </h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Delusions</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i>Hallucinations</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Disorganized thinking </li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> abnormal motor behavior </li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> Lack of motivation</li>
                                    </ul>
                                </div>




                                <div className = {c.one} id = 'paranoia'>
                                    <div className = {c.title}>
                                        <h2>PARANOIA </h2>
                                    </div>
                                    <p>Paranoia is the feeling that you’re being threatened in some way, such as people watching you or acting against you, even though there’s no proof that it’s true. It happens to a lot of people at some point. Even when you know that your concerns aren’t based in reality, they can be troubling if they happen too often.</p>
                                  
                                    
                                    <h3> Symptoms of Paranoia </h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> believing that others have hidden motives</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> doubting the loyalty of others</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> being hypersensitive to criticism</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> having trouble working with others</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i>being quick to become angry and hostile</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> becoming detached or socially isolated</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i>having trouble relaxing</li>
                                    </ul>
                                </div>

                                <div className = {c.two} id = 'panic-disorder'>
                                    <div className = {c.title}>
                                        <h2> PANIC  DISORDER  </h2>
                                    </div>
                                    <p>Panic disorder occurs when you experience recurring unexpected panic attacks. The DSM-5 defines panic attacks as abrupt surges of intense fear or discomfort that peak within minutes. People with the disorder live in fear of having a panic attack. You may be having a panic attack when you feel sudden, overwhelming terror that has no obvious cause. You may experience physical symptoms, such as a racing heart, breathing difficulties, and sweating.</p>
                                    <p>Even though the symptoms of this disorder can be quite overwhelming and frightening, they can be managed and improved with treatment. Seeking treatment is the most important part of reducing symptoms and improving your quality of life.</p>

                                    <h3>symptoms of panic disorder </h3>
                                    <ul>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> shortness of breath</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i>feeling like you are choking</li>
                                        <li><i className="fas fa-long-arrow-alt-right"></i> chest pain or tightness </li>

                                    </ul>
                                </div>
                    </div>
                    

                    


                    
            </div>
        )
    }
}


export default Psypage;