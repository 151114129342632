import React from 'react';
import './Dropdown.css'
import MentalPng from '../../../Images/psy.png'
import {Link } from 'react-router-dom'

class DropdownThree extends React.Component{
    onClick = () => {
        this.props.Propref.current.classList.remove('show_dropdown_three')
    }
    render() {
        return(
            <section className = 'dropdown_one' ref = {this.props.Propref}>
                <div className = 'dropdown_one_container'>
                    <div className = 'section_one'>
                        <img src = {MentalPng} alt = 'Addiction'></img>
                    </div>
                    <div className = 'section_two'>
                        <h3 onClick = {this.onClick}><Link to = '/psy/home'>Psychological Disorders</Link></h3>
                        <ul className = 'uls'>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/phobia'>Phobia </Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/depression'>Depresssion</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/ptsd'>PTSD</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/ocd'>OCD</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/bipolar'>Bipolar</Link></li>
                        </ul>
                    </div>
                    <div className = 'section_three'>
                    
                        <ul className = 'uls'>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/insomnia'>Insomnia</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/eating-disorder'>Eating Disorder</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/schizophrenia'>Schizophrenia</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/paranoia'>Paranoia</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/psy/panic-disorder'>Panic Disorder</Link></li>

                        </ul>
                    </div>
                </div>
                <p>
                Don’t keep delaying the act of asking for help. Finding the courage to speak with an addiction professional may be the first most significant step on your journey to recovery.
                
                </p>
            </section>
        )
    }
}

export default DropdownThree;