import React from 'react';
import './Dropdown.css'
import HospitalPng from '../../../Images/dropdownfour.png'
import { Link } from 'react-router-dom'
class DropdownFour extends React.Component{
    onClick = () => {
        this.props.Propref.current.classList.remove('show_dropdown_four')
    }
    render() {
        return(
            <section className = 'dropdown_one' ref = {this.props.Propref}>
                <div className = 'dropdown_one_container'>
                    <div className = 'section_one'>
                        <img src = {HospitalPng} alt = 'Addiction'></img>
                    </div>
                    <div className = 'section_two'>
                       
                        <ul className = 'uls'>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/faq'>FAQ'S</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/programs/inpatient-addiction-rehab'>Where To Start</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/about/leadership'>Leadership</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/about/ethics'>Ethics</Link></li>

                        </ul>
                    </div>
                    <div className = 'section_three'>
                    
                        <ul className = 'uls'>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/our-facility'>Our Campus</Link></li>
                           
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/about/our-history'>Our History</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/about/why-sthree-kendra'>Why Sthree Kendra</Link></li>

                        
                        </ul>
                    </div>
                </div>
                <p>
                Successfully breaking free from addiction requires taking it one step at a time. You don’t have to get it right immediately.
                </p>
            </section>
        )
    }
}

export default DropdownFour;