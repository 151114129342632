import React from 'react';
import './Dropdown.css'
import YogaPng from '../../../Images/yogapng.png'
import { Link } from 'react-router-dom'
class DropdownTwo extends React.Component{
    onClick = () => {
        this.props.Propref.current.classList.remove('show_dropdown_two')
    }
    render() {
        return(
            <section className = 'dropdown_one' ref = {this.props.Propref}>
                <div className = 'dropdown_one_container'>
                    <div className = 'section_one'>
                        <img src = {YogaPng} alt = 'Addiction'></img>
                    </div>
                    <div className = 'section_two'>
                        <h3 onClick = {this.onClick}><Link to  = '/programs/home'>Our Programs</Link></h3>
                        <ul className = 'uls'>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/programs/inpatient-addiction-rehab'>Inpatient Addiction Rehab </Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/programs/12-step-immersion'>12-Step Immersion</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/programs/spiritual-coaching'>Spiritual Coaching</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/programs/life-skills'>Life Skills</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/programs/food-nutrition'>Food & Nutrition</Link></li>
                        </ul>
                    </div>
                    <div className = 'section_three'>
                    <h3 onClick = {this.onClick}><Link to  = '/treatments/home'>Our Treatment</Link></h3>
                        <ul className = 'uls'>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/treatments/counselling'>Counselling</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/treatments/family-therapy'>Family Therapy</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/treatments/best-enivronment'>Best Environment</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/treatments/individual-counselling'>Individual Counselling</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/treatments/group-counselling'>Group Counselling</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to  = '/treatments/entertainment'>Entertainment</Link></li>
                        </ul>
                    </div>
                </div>
                <p>
                No matter how good your intentions are, they amount to nothing if you fail to take the necessary steps to make them happen. Wishing for recovery isn’t enough. Take the first step by accepting help from an addiction treatment program.
                </p>
            </section>
        )
    }
}

export default DropdownTwo;