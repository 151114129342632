import React from 'react'
import DesktopView from './DesktopView/DesktopView'
import MobileView from './MobileView/MobileView'
class NavBar extends React.Component{

    render() {
        return(
            <div>
                <DesktopView></DesktopView>
                <MobileView></MobileView>
            </div>
        )
    }
}

export default NavBar;