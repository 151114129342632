import React from 'react';
import  './Dropdown.css'
import AddictionImg from '../../../Images/showtime.png'
import { Link } from 'react-router-dom';


class DropdownOne extends React.Component{
    componentDidMount () {
      
    }
    onClick = () => {
        this.props.Propref.current.classList.remove('show_dropdown_one')
    }
    render() {
        return(
            <section className = 'dropdown_one' ref = {this.props.Propref}>
                <div className = 'dropdown_one_container'>
                    <div className = 'section_one'>
                        <img src = {AddictionImg} alt = 'Addiction'></img>
                    </div>
                    <div className = 'section_two'>
                        <h3 onClick = {this.onClick}><Link to = '/addiction/home'>What is Addiction?</Link></h3>
                        <ul className = 'uls'>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to ='/addiction/signs-of-addiction'>Signs of Addiction</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/addiction/addiction-test'>Addiction Test</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/addiction/stigma-of-addiction'>Stigma of Addiction</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/addiction/dealing-with-addiction'>Dealing with Addiction</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/addiction/addiction-prevention'>Addiction Prevention</Link></li>
                        </ul>
                    </div>
                    <div className = 'section_three'>
                        <h3  onClick = {this.onClick}><Link to = '/typesofaddiction/home'>Types of Addiction</Link> </h3>
                        <ul  className = 'uls'>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/typesofaddiction/Addreall'>Adderall</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/typesofaddiction/Alcohol'>Alcoholism</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/typesofaddiction/Cocaine'>Cocaine</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/typesofaddiction/Marijuana'>Marijuana</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/typesofaddiction/Meth'>Methamphetamine</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/typesofaddiction/Heroin'>Opioids/Heroin</Link></li>
                            <li onClick = {this.onClick}><i className="fas fa-caret-right"></i><Link to = '/typesofaddiction/Other'>Other Addictions</Link></li>
                        </ul>
                    </div>
                </div>
                <p>
                We are addicted to our thoughts. We cannot change anything if we cannot change our thinking.
                </p>
            </section>
        )
    }
}

export default DropdownOne;