import React from 'react'
import c from './AboutPage.module.css'
import { Helmet } from 'react-helmet';

class AboutPage extends React.Component{
    componentDidUpdate() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    render() {
        return(
            <div className = {c.main} id = 'home'>
                <Helmet>
                <meta name="description" content="We have the answer to your drug and alcohol rehab problems. If you or a loved one are suffering from addiction then give us a call now at +91 888-462-5127."/>
                </Helmet>
                    <header>
                        <h3>About Us</h3>
                    </header>
                    <div className = {c.container}>
                        <div className = {c.one}>
                            <h3>About Sthree Kendra</h3>
                            <p>Sthree Kendra specializes in working with clients who are chronic relapsers, dual diagnosis, or treatment-resistant. We’re committed to helping our clients effect real change in their lives, and we can prove it.Clients who have completed the Sthree Kendra's 12-Step immersion program and transitioned into sober living experience a 77% success rate and significant quality of life improvements.</p>
                            <p>Sthree Kendra is conveniently located in Hesaraghatta Main Road, Bengaluru, Karnataka. We are accessible by car, given our proximity to other major Karnataka cities including Mysore, Tumkur, as well as air, as we are located approximately 20 miles from Bengaluru International Airport. While many of our residents are from Karnataka, we have residents come to us from around the India and internationally.</p>
                        </div>
                        
                        <div className = {c.one} id = 'why-sthree-kendra'>
                            <h3>Why Sthree Kendra</h3>
                            <p>Sthree Kendra has been saving lives and restoring families from substance abuse from  last 4 years. Our addiction treatment experts have developed the field’s most trusted and comprehensive continuum of care—including inpatient rehab programs with integrated services for co-occurring addiction and mental health disorders, sober living options and recovery management and resources—in order to provide you with the right level of care to meet your specific needs as you heal and regain your life.</p>  
                        </div>

                        <div className = {c.one} id = 'ethics'>
                            <h3>Code of Ethics</h3>
                            <p>At Sthree Kendra, our code of ethics provides a foundation for everything we do. We are committed to helping provide our clients with the highest possible standards of care for addiction and mental health concerns.</p>
                            <div className = {c.one_sub}>
                                <h4>Non-Discrimination:</h4>
                                <p>
                                    We promise to respect diversity and to treat all clients with respect, dignity and empathy, regardless of their age, sexual orientation, ethnic and racial background, religious and spiritual beliefs, marital status, politics, diagnosis, personal history or mental/physical disability.
                                </p>
                                <p>We will treat all individuals with impartiality and objectivity, and will never impose our personal values on our clients or allow personal or professional issues to intrude into the treatment relationship.</p>
                            </div>

                            <div className = {c.one_sub}>

                            </div>
                        </div>
                        <div className = {c.leadership_div} id = 'leadership'>
                            <h3>Leadership</h3>
                            <div className = {c.leadership}>
                                <div className = {c.nine}>
                                    <h3>Mrs. Geetha Raju</h3>
                                    <p>President</p>
                                </div>

                                <div className = {c.nine}>
                                    <h3>Dr. Anupama M.S</h3>
                                    <p>Vice President (Psychologist)</p>
                                </div>



                                <div className = {c.nine}>
                                    <h3>Ms. Amrutha Raj</h3>
                                    <p>Secretary</p>
                                </div>


                                <div className = {c.nine}>
                                    <h3>Dr. Shashidhar S. Billagi</h3>
                                    <p>Psychiatric, MBBS, DPM, RGUHS</p>
                                </div>


                                <div className = {c.nine}>
                                    <h3>Dr. Shreelatha</h3>
                                    <p>Psychiatric</p>
                                </div>
                                
                                <div className = {c.nine}>
                                    <h3>Dr. Balakrishna H.D.</h3>
                                    <p>Physician</p>
                                </div>

                                
                                <div className = {c.nine}>
                                    <h3>Mrs. Sampurna B. Sasalatti</h3>
                                    <p>M.S.W.</p>
                                </div>

                                <div className = {c.nine}>
                                    <h3>Mrs. Hemalatha M</h3>
                                    <p>Manager</p>
                                </div>
                            </div>  
                        </div>                  

                        <div className = {c.one} id = 'our-history'>
                            <h3>Our History</h3>
                            <p>Established in 2016 on Bengaluru City, Karnataka, the Sthree Kendra had its beginnings in a compassionate, holistic and forward-thinking approach to the disease of alcoholism. Today, the Sthree Kendra  is recognized as leading  organization singularly focused on providing healing and hope to individuals, families and communities affected by addiction to alcohol and other drugs.</p>
                        </div>



                    </div>
            </div>
        )
    }
}

export default AboutPage;