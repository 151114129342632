import React from 'react'
import c from './ContactPage.module.css'
import Axios from 'axios';
class ContactPage extends React.Component{
    constructor(props) {
        super(props)
        this.ipOne = React.createRef();
        this.ipTwo = React.createRef();
        this.ipThree = React.createRef();
        this.ipFour = React.createRef();

        this.labelOne = React.createRef();
        this.labelTwo = React.createRef();
        this.labelThree = React.createRef();
        this.labelFour = React.createRef();

        this.spanOne = React.createRef();
        this.spanTwo = React.createRef();
        this.spanThree = React.createRef();
        this.spanFour = React.createRef();

        this.hideandshow = React.createRef();

    }
    componentDidMount() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
        this.ipOne.current.addEventListener('focusin', () => {
         
            this.labelOne.current.classList.add(c.label_style)
        })
        this.labelOne.current.addEventListener('click', () => {
         
            this.labelOne.current.classList.add(c.label_style)
        })


        this.ipOne.current.addEventListener('focusout', () => {
            if (this.ipOne.current.value == '') {
                this.labelOne.current.classList.remove(c.label_style)
            }
           
        })


        this.ipTwo.current.addEventListener('focusin', () => {
           
            this.labelTwo.current.classList.add(c.label_style)
        })
        this.labelTwo.current.addEventListener('click', () => {
           
            this.labelTwo.current.classList.add(c.label_style)
        })

        this.ipTwo.current.addEventListener('focusout', () => {
            if (this.ipTwo.current.value == '') {
                this.labelTwo.current.classList.remove(c.label_style)
            }
          
        })

        this.ipThree.current.addEventListener('focusin', () => {
           
            this.labelThree.current.classList.add(c.label_style)
        })
        this.labelThree.current.addEventListener('click', () => {
           
            this.labelThree.current.classList.add(c.label_style)
        })

        this.ipThree.current.addEventListener('focusout', () => {
            if (this.ipThree.current.value == '') {
                this.labelThree.current.classList.remove(c.label_style)
            }
          
        })

        this.ipFour.current.addEventListener('focusin', () => {
           
            this.labelFour.current.classList.add(c.label_style)
        })
        this.labelFour.current.addEventListener('click', () => {
           
            this.labelFour.current.classList.add(c.label_style)
        })
        this.ipFour.current.addEventListener('focusout', () => {
            if (this.ipFour.current.value == '') {
                this.labelFour.current.classList.remove(c.label_style)
            }
        })
        
    
        
        this.ipOne.current.addEventListener('input', () => {
           
            if (this.ipOne.current.value.length > 2) {
                this.ipOne.current.classList.remove(c.style_border_red);
                this.ipOne.current.classList.add(c.style_border_green)
       
            } else {
                this.ipOne.current.classList.remove(c.style_border_green)
                this.ipOne.current.classList.add(c.style_border_red);
    
            }
        })


        this.ipTwo.current.addEventListener('input', () => {
           
            if (this.ipTwo.current.value.length > 2) {
                this.ipTwo.current.classList.remove(c.style_border_red);
                this.ipTwo.current.classList.add(c.style_border_green)
       
            } else {
                this.ipTwo.current.classList.remove(c.style_border_green)
                this.ipTwo.current.classList.add(c.style_border_red);
    
            }
        })


        this.ipThree.current.addEventListener('input', () => {
           
            if (this.ipThree.current.value.length > 2 && this.ipThree.current.value.indexOf('@') >= 0) {
                this.ipThree.current.classList.remove(c.style_border_red);
                this.ipThree.current.classList.add(c.style_border_green)
       
            } else {
                this.ipThree.current.classList.remove(c.style_border_green)
                this.ipThree.current.classList.add(c.style_border_red);
    
            }
        })


        this.ipFour.current.addEventListener('input', () => {
           
            if (this.ipFour.current.value.length > 2) {
                this.ipFour.current.classList.remove(c.style_border_red);
                this.ipFour.current.classList.add(c.style_border_green)
       
            } else {
                this.ipFour.current.classList.remove(c.style_border_green)
                this.ipFour.current.classList.add(c.style_border_red);
    
            }
        })
    }
  
    onSubmit = (e) => {
        e.preventDefault();
       
        if (this.ipOne.current.value  === '') {
            this.spanOne.current.classList.add(c.show_span)
        } else {
            this.spanOne.current.classList.remove(c.show_span)
        }

        if (this.ipTwo.current.value  === '') {
            this.spanTwo.current.classList.add(c.show_span)
        } else {
            this.spanTwo.current.classList.remove(c.show_span)
        }

        if (this.ipThree.current.value  === '') {
            this.spanThree.current.classList.add(c.show_span)
        } else {
            this.spanThree.current.classList.remove(c.show_span)
        }

        if (this.ipFour.current.value  === '') {
            this.spanFour.current.classList.add(c.show_span)
        } else {
            this.spanFour.current.classList.remove(c.show_span)
        }
        const name = this.ipOne.current.value;
        const phone = this.ipTwo.current.value;
        const email = this.ipThree.current.value;
        const message = this.ipFour.current.value;
        if (name && email && phone && message) {
            this.hideandshow.current.classList.add(c.show_aftermsg);
       
            setTimeout(()=> {
                this.hideandshow.current.classList.remove(c.show_aftermsg);
                this.ipOne.current.value = ''
                this.ipTwo.current.value = ''
                this.ipThree.current.value = '';
                this.ipFour.current.value = ''
            },6000)
            Axios.post('https://sthreekendra.herokuapp.com/sendmessage', {name,email,phone,message})
                .then((res) => {
                    console.log(res)
                })
        }

  
    }
    componentDidUpdate() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    render() {
        return (
            <div className = {c.main} id = 'home'>
                    <header>
                        <h3>Contact Us</h3>
                    </header>
                    <div className = {c.container}>
                        <div className = {c.container_one}>
                                <div className = {c.content}>
                                    <h3>Contact Sthree Kendra!</h3>
                                    <p>Your journey to permanent recovery begins with a single phone call. Contact a Sthree Kendra Specialist 24 hours a day, 7 days a week by calling +91 8884730316.</p>
                                </div>
                                <div className = {c.address}>
                                    <div className = {c.box_one}>
                                        <h3>Address:</h3>
                                        <p>6, M.E.I. Layout, Bagalagunte, Hesaraghatta Main Road, Bengaluru-560073</p>
                                    </div>

                                    <div className = {c.box_one}>
                                        <h3>Phone:</h3>
                                        <p>+91 9986510457</p>
                                        <p>+91 8884730316</p>
                                    </div>

                                    <div className = {c.box_one}>
                                        <h3>Email:</h3>
                                        <p>info@sthreekendra.com</p>
                                        <p>amrutha4raj94@gmail.com</p>
                                        <p>divijraj59@gmail.com</p>
                                    </div>
                                </div>
                        </div>
                        <div className = {c.container_two} id = 'contact'>
                                <h3>GET IN TOUCH</h3>
                                <form className = {c.form} onSubmit = {this.onSubmit}>
                                    <div className = {c.field}>
                                        <label ref = {this.labelOne}>Name</label><br></br>
                                        <input onInput = {this.onInput} ref = {this.ipOne} type = 'text' id = 'contact_name'></input>
                                        <span ref = {this.spanOne} className = {c.required}>required</span>
                                    </div>
                                    <div className = {c.field}>
                                        <label ref = {this.labelTwo}>Phone</label><br></br>
                                        <input onInput = {this.onInput}  ref = {this.ipTwo} type = 'text' id = 'contact_phone'></input>
                                        <span ref = {this.spanTwo} className = {c.required}>required</span>
                                    </div>
                                    <div className = {c.field}>
                                        <label ref = {this.labelThree}>E-mail</label><br></br>
                                        <input onInput = {this.onInput}  ref = {this.ipThree} type = 'text' id = 'contact_email'></input>
                                        <span ref = {this.spanThree} className = {c.required}>required</span>
                                    </div>
                                    <div className = {c.field}>
                                        <label ref = {this.labelFour}>Message</label><br></br>
                                        <input onInput = {this.onInput}  ref = {this.ipFour} type = 'text' id = 'contact_message'></input>
                                        <span ref = {this.spanFour} className = {c.required}>required</span>
                                    </div>
                                    <div className = {c.field}>
                                        <button>Submit</button>
                                    </div>
                                   <p className = {c.aftermsg} ref = {this.hideandshow}>Thank you for reaching out to sthree kendra we will contact you soon</p>
                                </form>
                        </div>
                    </div>
            </div>
        )
    }
}


export default ContactPage;