import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import NavBar from './Components/NavBar/NavBar'
import Footer from './Components/Footer/Footer'
import HomePage from './Components/Homepage/Homepage'
import AddictionPage from './Components/AddictionPage/index'
import TypesOfAddiction from './Components/AddictionPage/TypesOfAddiction'
import ProgramsPage from './Components/ProgramsPage/ProgramsPage'
import MoveTop from './MoveTop'
import TreatmentPage from './Components/TreatmentPage/TreatmentPage'
import PsyPage from './Components/PsyPage/Psypage'
import ContactPage from './Components/ContactPage/ContactPage'
import FaqPage from './Components/About/FaqPage'
import AboutPage from './Components/About/AboutPage'
import Profile from './Components/Profile/Profile'
import Login from './Components/Profile/Login'
import GalleryPage from './Gallery/GalleryPage'
const AppRouter = () => {

    return (
        <BrowserRouter>
            <NavBar></NavBar>
            <MoveTop></MoveTop>
            <Switch>
                <Route path = '/' component = {HomePage} exact></Route>
                <Route path = '/addiction/:id' component = {AddictionPage}></Route>
                <Route path = '/typesofaddiction/:id' component = {TypesOfAddiction}></Route>
                <Route path = '/programs/:id' component = {ProgramsPage}></Route>
                <Route path = '/treatments/:id' component = {TreatmentPage}></Route>
                <Route path = '/psy/:id' component = {PsyPage}></Route>
                <Route path = '/contact/:id' component = {ContactPage}></Route>
                <Route path = '/faq' component = {FaqPage}></Route>
                <Route path = '/about/:id' component = {AboutPage}></Route>
                <Route path = '/divij' component = {Profile}></Route>
                <Route path = '/login' component = {Login}></Route>
                <Route path ='/our-facility' component = {GalleryPage}></Route>
 
                <Route component = {()=>(<div>Not Found</div>)}></Route>
            </Switch>
            <Footer></Footer>
        </BrowserRouter>
    )
}

export default AppRouter;