import React from 'react'
import c from './Programs.module.css'
import ImgOne from '../../Images/program1.svg';
import ImgTwo from '../../Images/program2.svg'
import ImgThree from '../../Images/program3.svg'
import ImgFour from '../../Images/program4.svg'
import { Link } from 'react-router-dom';
class Programs extends React.Component{

    render() {

        return(
            <div className = {c.main}>
                <div className = {c.title}>Programs We Offer</div>
                <div className = {c.container}>
                    <div className = {c.one}>
                        <div>Primary Programs</div>
                        <p>Meeting the unique needs of patients and families with customized programs.</p>
                        <Link to = '/programs/home'>
                        <button>
                           View More
                        </button>
                        </Link>
                    </div>
                    <div className = {c.two}>
                        
                                

                        <div className = {c.one_div}>
                            <div className = {c.one_fill_container}>
                                <div className = {c.one_fill_front}>
                                    <img className = {c.imgsvg} src = {ImgTwo} alt = ''/>
                                    Inpatient Addiction Rehab
                                </div>                                  
                                <div className = {c.one_fill_back}>
                                    Reclaiming the lives of individuals and families coping with addiction and behavioral health issues.
                                </div>
                            </div>
                        </div>

                        <div className = {c.two_div}>
                                <div className = {c.two_fill_container}>
                                <div className = {c.two_fill_front}>
                                    <img className = {c.imgsvg} src = {ImgOne} alt = ''/>
                                    <p>12-Step Immersion</p>
                                </div>                                  
                                <div className = {c.two_fill_back}>
                                Experience healing together, with Sthree Kendra.
                                </div>
                            </div>
                        </div>
                

                        <div className = {c.three_div}>
                              
                                <div className = {c.three_fill_container}>
                                    <div className = {c.three_fill_front}>
                                        <img className = {c.imgsvg}  src = {ImgThree} alt = ''/>
                                        <p>Spiritual Coaching</p>
                                    </div>                                  
                                    <div className = {c.three_fill_back}>
                                    Stay connected with the freedom and joy of recovery at the Sthree Kendra.
                                    </div>
                                </div>
                        </div>
                        <div className = {c.four_div}>
                                
                             

                                <div className = {c.four_fill_container}>
                                    <div className = {c.four_fill_front}>
                                    <img className = {c.imgsvg} src = {ImgFour} alt = ''/>
                                        <p>Life Skills</p>
                                    </div>                                  
                                    <div className = {c.four_fill_back}>
                                    Establish independence by learning key life skills, like organization, time management, and financial wellness.
                                    </div>
                                </div>
                        </div>
                    
                
                        </div>
                </div>
                <div className ={c.btn_div}>
                <Link to = '/programs/home'>
                <button className = {c.btn}>
                  View More  </button></Link>
                </div>
            </div>
            
        )
    }
}


export default Programs;