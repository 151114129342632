import React from 'react'
import c from './GalleryPage.module.css'
import Img1 from './gallery/1.jpg'
import Img2 from './gallery/2.jpg'
import Img3 from './gallery/3.jpg'
import Img4 from './gallery/4.jpg'
import Img5 from './gallery/5.jpg'
import Img6 from './gallery/6.jpg'
import Img7 from './gallery/7.jpg'
import Img8 from './gallery/8.jpg'
import Img9 from './gallery/9.jpg'
import Img10 from './gallery/10.jpg'
import Img11 from './gallery/11.jpg'
import Img12 from './gallery/12.jpg'
import Img13 from './gallery/13.jpg'
class GalleryPage extends React.Component{

    render() {
        return(
            <div className = {c.main}>
                <header>
                    <h3>Our Facility</h3>
                    <p> Sthree Kendra offers a peaceful, community-oriented environment where those in recovery can transition back into their day-to-day lives.</p>
                </header>
               
                <div className = {c.container}>
                    <div className = {c.one}>
                        <img src = {Img1} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img2} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img3} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img4} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img5} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img6} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img7} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img8} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img9} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img10} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img11} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img12} alt = ''></img>
                    </div>
                    <div className = {c.one}>
                        <img src = {Img13} alt = ''></img>
                    </div>

                </div>
            </div>
        )
    }
}


export default GalleryPage;