import React from 'react'
import c from './Location.module.css'
class Location extends React.Component{
    constructor(props) {
        super(props)
        this.locationRef = React.createRef()
    }
    componentDidMount() {
        const location = this.locationRef.current
            
    }
    render() {
        return(
            <div className = {c.main}>
            <div className = {c.container}>
                <div className = {c.info}>Meet Us Here <i className="fas fa-map-marker-alt"></i></div>
                <div id = 'location' className = {c.location} ref = {this.locationRef}></div>
            </div>
            </div>
        )
    }
}

export default Location;