import React from 'react'
import c from './MoveTop.module.css'


class Message extends React.Component{
    constructor(props) {
        super(props)
        this.mybutton = React.createRef();
    }
 
    componentDidMount() {
        
        let scrollFunction = () => {

            if (window.innerWidth <= 600) {
                if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
               
                    this.mybutton.current.classList.add(c.mybutton)
            
                } else {
                    this.mybutton.current.classList.remove(c.mybutton)
                }
            } else {
                if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
               
                    this.mybutton.current.classList.add(c.mybutton)
            
                } else {
                    this.mybutton.current.classList.remove(c.mybutton)
                }
                }
            }
          
        window.onscroll = function() {scrollFunction()};

    }
    topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    render() {
        return (
            <div ref = {this.mybutton} onClick = {this.topFunction} className = {c.container}>
                  <i className="fas fa-angle-up"></i>
                  <p>Top</p>
            </div>
        )
    }
}


export default Message;