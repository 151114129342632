import React from 'react'
import c from './FaqPage.module.css'

class FaqPage extends React.Component{
    constructor(props) {
        super(props)
        this.qOneRef = React.createRef();
        this.aOneRef = React.createRef();

        this.qTwoRef = React.createRef();
        this.aTwoRef = React.createRef();

        this.qThreeRef = React.createRef();
        this.aThreeRef = React.createRef();

        this.qFourRef = React.createRef();
        this.aFourRef = React.createRef();

        this.qFiveRef = React.createRef();
        this.aFiveef = React.createRef();

        this.qSixRef = React.createRef();
        this.aSixRef = React.createRef();

        this.qSevenRef = React.createRef();
        this.aSevenRef = React.createRef();

        this.qEightRef = React.createRef();
        this.aEightRef = React.createRef();
    }
    componentDidMount() {
        this.qOneRef.current.addEventListener('click', () => {
            this.qOneRef.current.classList.toggle(c.change_b_one)
            this.aOneRef.current.classList.toggle(c.show_answer_one);
        })

        this.qTwoRef.current.addEventListener('click', () => {
            this.aTwoRef.current.classList.toggle(c.show_answer_two);
            this.qTwoRef.current.classList.toggle(c.change_b_two)
        })

        this.qThreeRef.current.addEventListener('click', () => {
            this.aThreeRef.current.classList.toggle(c.show_answer_three);
            this.qThreeRef.current.classList.toggle(c.change_b_three)
        })

        this.qFourRef.current.addEventListener('click', () => {
            this.aFourRef.current.classList.toggle(c.show_answer_four);
            this.qFourRef.current.classList.toggle(c.change_b_four)
        })


        // this.qFiveRef.current.addEventListener('click', () => {
        //     this.aFiveRef.current.classList.toggle(c.show_answer_five);
        // })

        this.qSixRef.current.addEventListener('click', () => {
            this.aSixRef.current.classList.toggle(c.show_answer_six);
            this.qSixRef.current.classList.toggle(c.change_b_six)
        })

        this.qSevenRef.current.addEventListener('click', () => {
            this.aSevenRef.current.classList.toggle(c.show_answer_seven);
            this.qSevenRef.current.classList.toggle(c.change_b_seven)
        })

        // this.qEightRef.current.addEventListener('click', () => {
        //     this.aEightRef.current.classList.toggle(c.show_answer_eight);
        //     this.qEightRef.current.classList.toggle(c.change_b_eight)
        // })
    }
    render() {
        return (
            <div className = {c.main}>
                    <header>
                        <h3>FAQ'S</h3>
                        <p>Do you have questions about our therapeutic approach, location, or admissions process? You’ve come to the right page. Scroll down to learn more about Sthree Kendra.</p>
                    </header>
                    <div className = {c.container}>
                       <h3>Frequently Asked Questions</h3> 
                       <div className = {c.one}>
                           <div className = {c.question} ref = {this.qOneRef}>
                                <p>Is Sthree Kendra a licensed alcohol and drug treatment center?</p>
                                <h4> <i className="fas fa-plus"></i> </h4>
                                
                           </div>
                           <div className = {c.answer} ref = {this.aOneRef}>
                               <p>Sthree Kendra Clinical Center is licensed to provide substance abuse treatment by the Bengaluru Department of State Health Services.</p>
                           </div>
                       </div>
                       <div className = {c.one}>
                           <div className = {c.question} ref = {this.qTwoRef}>
                                <p>How do you conduct admissions?</p>
                                <h4><i className="fas fa-plus"></i></h4>
                           </div>
                           <div className = {c.answer} ref = {this.aTwoRef}>
                               <p>Sthree Kendra admissions are coordinated by appointment only..</p>
                           </div>
                       </div>
                       <div className = {c.one}>
                           <div className = {c.question} ref = {this.qThreeRef}>
                                <p>Do you accept insurance?</p>
                                <h4><i className="fas fa-plus"></i></h4>
                           </div>
                           <div className = {c.answer} ref = {this.aThreeRef}>
                               <p>Sthree Kendra not works with any insurance companies. We provide payment options only ..</p>
                           </div>
                       </div>

                       <div className = {c.one}>
                           <div className = {c.question} ref = {this.qFourRef}>
                           <p>Where is Sthree Kendra located?</p>
                                <h4><i className="fas fa-plus"></i></h4>
                           </div>
                           <div className = {c.answer} ref = {this.aFourRef}>
                               <p>Sthree Kendra is conveniently located in Hesaraghatta Main Road, Bengaluru, Karnataka. We are accessible by car, given our proximity to other major Karnataka cities including Mysore, Tumkur, as well as air, as we are located approximately 20 miles from Bengaluru International Airport. While many of our residents are from Karnataka, we have residents come to us from around the India and internationally.</p>
                           </div>
                       </div>
{/* 
                       <div className = {c.one}>
                           <div className = {c.question} ref = {this.qFiveRef}>
                                <p>Where is Sthree Kendra located?</p>
                                <h4><i className="fas fa-plus"></i></h4>
                           </div>
                           <div className = {c.answer} ref = {this.aFiveRef}>
                               <p>Sthree Kendra is conveniently located in Hesaraghatta Main Road, Bengaluru, Karnataka. We are accessible by car, given our proximity to other major Karnataka cities including Mysore, Tumkur, as well as air, as we are located approximately 20 miles from Bengaluru International Airport. While many of our residents are from Karnataka, we have residents come to us from around the India and internationally.</p>
                           </div>
                       </div> */}

                       <div className = {c.two}>
                           <div className = {c.question} ref = {this.qSixRef}>
                                <p>What are the most significant benefits of attending Sthree Kendra?</p>
                                <h4><i className="fas fa-plus"></i></h4>
                           </div>
                           <div className = {c.answer} ref = {this.aSixRef}>
                               <p>Benefits of the Sthree Kendra programs include:</p>
                               <ul>
                                   <li><i className="fas fa-long-arrow-alt-right"></i> 12-Step Immersion Program.</li>
                                   <li><i className="fas fa-long-arrow-alt-right"></i>15-month extended continuum of care, which includes our 12-month Aftercare Monitoring Program.</li>
                                   <li><i className="fas fa-long-arrow-alt-right"></i>Learning through dialogue and discussion, not lecture.</li>
                                   <li><i className="fas fa-long-arrow-alt-right"></i>Life Skills Training that equips residents with the skills they need to succeed in life after treatment.</li>
                                   <li><i className="fas fa-long-arrow-alt-right"></i>Dual Diagnosis Treatment: Proven clinical care for co-occurring disorders like anxiety, depression, and more.</li>
                                   <li><i className="fas fa-long-arrow-alt-right"></i>Daily exercise and nutritional guidance.</li>
                                   <li><i className="fas fa-long-arrow-alt-right"></i>Countryside setting with ample access to recreational activities.</li>
                               </ul>
                           </div>
                           
                       </div>


                       <div className = {c.one}>
                           <div className = {c.question} ref = {this.qSevenRef}>
                                <p>What are your staff’s qualifications and credentials?</p>
                                <h4><i className="fas fa-plus"></i></h4>
                           </div>
                           <div className = {c.answer} ref = {this.aSevenRef}>
                               <p>Almost all members of the Sthree Kendra team have worked in the field of chemical dependency treatment for years, and many are in recovery from alcohol and drug addiction and have wholeheartedly adopted the way of living taught in Sthree Kendra's unique program. As such, we teach from practical experience, not theory or opinion.</p>
                               <p>We are proud to have a team outfitted with credentials. Our Clinical Center is staffed by Masters-level clinicians, including Licensed Professional Counselors and Licensed Chemical Dependency Counselors.</p>
                           </div>
                       </div>

              

                    </div>
            </div>
        )
    }
}

export default FaqPage;