import React from 'react'
import c from './TreatmentPage.module.css'
import ImgOne from './images/one.jpg'
import ImgTwo from './images/two.jpg'
import ImgThree from './images/three.jpg'
import ImgFour from './images/four.jpg'
import ImgFive from './images/five.jpg'
import ImgSix from './images/six.jpg'
import Helmet from 'react-helmet'
class TreatmentPage extends React.Component{
    componentDidUpdate() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    render() {
        return(
            <div className = {c.main} id = 'home'>
                <Helmet>
                <meta name="description" content="From intervention to aftercare, Sthree Kendra's services inspire and empower residents to lead more fulfilling, productive lives in sobriety."/>
                </Helmet>
                <header>
                  <h3>  Our Treatments</h3>
                </header>
                <div className = {c.container}>

                    <div className = {c.one_main_container} >
                            
                            <div className = {c.one_container} id = 'counselling'>
                                <img src = {ImgOne} alt = ''></img>
                                <h3>Counselling</h3>
                                <p>
                                Recovery from alcohol, gambling, drugs, food and other addictions is a long and often very difficult process. It is sometimes too easy to focus on the medical side of treatment and forget the emotional impact of addiction on your life and the lives of those around you.
                                </p>
                                <p>
                                That’s why here at Sthree Kendra we offer access a range of addiction counselling and therapy services which will not only help those who have addiction problems themselves but also provide a great deal of guidance, help and support to their families and close friends.
                                </p>
                            </div>
                            <div className = {c.one_container} id = 'family-therapy'>
                                <img src = {ImgTwo} alt = ''></img>
                                <h3>Family Therapy </h3>
                                <p>
                                Family therapy is an absolutely essential component of any treatment program for everyone involved. It is true when they say that addiction is a family disease, as it reaches everyone who is close to the addict. Sometimes, family members can suffer just as much or even more as the addict.
                                </p>
                                <p>
                                There are so many benefits to family therapy that they are difficult to list. First of all, it is important to remember that as a family disease, addiction can be unwillingly supported by family members who have no idea they are enabling the addict. In therapy, a counselor will educate the entire family unit about codependency and how to avoid common habits that can actually make addiction worse
                                </p>
                            </div>

                    </div>
                    <div className = {c.two_main_container}>
                        <div className = {c.one_container}  id = 'best-enivronment'>
                                    <img src = {ImgThree} alt = ''></img>
                                    <h3>Best Environment</h3>
                                    <p>
                                    Sthree Kendra on Drug Abuse points out that willpower and good intentions alone are rarely enough to end an addiction.1 That’s because addiction is a complex disease with a number of underlying causes that must be addressed in order to enjoy successful recovery. A high-quality treatment program is essential for overcoming an addiction, and the right treatment environment is paramount to success.
                                    </p>
                                    <p>
                                    The environment in which we live provides natural services for humans and all other species that are essential to our health, quality of life and survival.
                                    </p>
                                </div>
                       
                        <div className = {c.one_container}  id = 'individual-counselling'>
                                <img src = {ImgFour} alt = ''></img>
                                <h3>Individual Counselling</h3>
                                <p>
                                Our Clinical Team is incorporated with highly trained and educated counsellors who have a passion for supporting individuals facing the struggles of Addiction. Each individual that starts their Addiction Recovery journey will be administered one of our counsellors to support them through their goals.
                                </p>
                                <p>
                                For all, we promote a client-centred, holistic and compassionate role in Addiction Recovery – providing individuals with the tools and resources to help better understand the cycle of addiction, relapse prevention and more.
                                </p>
                            </div>

                    </div>

                    <div className = {c.three_main_container}  >
                        <div className = {c.one_container} id = 'group-counselling'>
                                    <img src = {ImgFive} alt = ''></img>
                                    <h3>Group Counselling</h3>
                                    <p>
                                    Group Therapy is a sort of psychological treatment that addresses specific problems such as depression, panic disorder, chronic pain, social anxiety, or substance abuse in a person. This kind of therapy is an ideal choice for making some positive changes in the life of a person depending on the nature of a problem. Together with a therapist and other group members, the person gets a support network to share life experiences and work better on understanding oneself.
                                    </p>
                                    <p>
                                    Sthree Kendra have launched Group therapy programs and sessions to help individuals and young adults who are in need of emotional assistance for a positive transition of life.
                                    </p>
                                </div>
                       
                        <div className = {c.one_container} id = 'entertainment'>
                                <img src = {ImgSix} alt = ''></img>
                                <h3>Entertainment</h3>
                                <p>
                                Whether currently going through rehab or have successfully completed treatment, activities are both beneficial and therapeutic to the process. Taking part in enjoyable activities can be important tools for making a smooth transition from addiction to recovery.
                                </p>
                                <p>
                                There will be leisure or entertainment activities scheduled by the facility that you will be expected to attend or take part in. It could be physical training or exercise, field trips, films or music, or a variety of other events intended to provide relief and relaxation in a protected setting.
                                </p>
                            </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default TreatmentPage;