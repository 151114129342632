import React from 'react'
import c from './Interval.module.css'
function Interval(props) {

    return(
        <div className = {c.main}>
            <div className = {c.container}>
                <div className = {c.one}>
                    <div className = {c.title}>Speak To An Addiction Specialist.</div>
                    <div className = {c.btn_div}>
                    <a  href = 'tel:+91 9986510457' ><button>Call +91 9986510457</button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Interval;