import React from 'react'
import c from './Profile.module.css'
import Axios from 'axios';
class Login extends React.Component {
    constructor(props){
        super(props);
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.state = {error: ""}
    }
    componentDidMount() {
        if (localStorage.getItem('email')) {
            this.props.history.push('/divij')
        }   
    }
    onSubmit = (e) => {
        e.preventDefault();

        Axios.post('https://sthreekendra.herokuapp.com/login', {email: this.emailRef.current.value, password: this.passwordRef.current.value})
            .then((res) => {
            
                localStorage.setItem('email', res.data.email);
                this.props.history.push('/divij')
            })
            .catch((err) => {
               this.setState({
                   error:err.response.data.error
               })
           
            })
    }
    render() {
        return(
            <div className = {c.main}>
                <header>
                    <h3>Login</h3>
                </header>

                <div className = {c.l_container}>
                    <p className = {c.error}>{this.state.error}</p>
                    <form className = {c.form} onSubmit = {this.onSubmit}>
                            <label>Email</label><br></br>
                            <input type = 'text' ref = {this.emailRef}></input><br></br>
                            <label>Password</label><br></br>
                            <input type = 'password' ref = {this.passwordRef}></input><br></br>
                            <button type = 'submit'>Login</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Login;