import React from 'react'
import c from './Stats.module.css'
import {Link} from 'react-router-dom'
function Stats(props) {

    return(
        <div className = {c.main}>
            <div className = {c.two}></div>
            <div className = {c.one}>
                <div className = {c.main_info}>
                    WHEN TREATMENT FAILS, THERES HOPE IN OUR MODEL
                </div>
                <div className = {c.content}>
                    <div className = {c.div_one}>
                        <div className = {c.logo}><i class="fas fa-hands-helping"></i></div>
                        <h1>2000+</h1>
                        <p>Families Treated</p>
                    </div>
                    <div className = {c.div_two}>
                        <div className = {c.logo}><i class="fas fa-hands-helping"></i></div>
                        <h1>99%</h1>
                        <p>Success Rate</p>
                    </div>
                    <div className = {c.div_three}>
                        <div className = {c.logo}><i class="fas fa-hands-helping"></i></div>
                        <h1>93%</h1>
                        <p>Reduction in Alcohol Abuse</p>
                    </div>
                    <div className = {c.div_four}>
                        <div className = {c.logo}><i class="fas fa-hands-helping"></i></div>
                        <h1>89%</h1>
                        <p>Reduction in Drug Abuse</p>
                    </div>
                    <div className = {c.div_five}></div>
                    <div className = {c.div_six}>
                      
                        <Link to = '/treatments/home'>Learn More</Link>
                    </div>    
                </div>
            </div>
           
        </div>
    )
}

export default Stats;