
import React from 'react'
import c from './ProgramsPage.module.css'
import OneImg from './images/one.jpg'
import TwoImg from './images/two.jpg'
import ThreeImg from './images/three.jpg'
import FourImg from './images/four.jpg'
import FiveImg from './images/five.jpg'
import Helmet from 'react-helmet'
class ProgramsPage extends React.Component{
    constructor(props) {
        super(props)
        this.learnMoreOne = React.createRef();
        this.oneContainerTwo = React.createRef();

        this.learnMoreTwo = React.createRef();
        this.twoContainerTwo = React.createRef()


        this.learnMoreThree = React.createRef();
        this.threeContainerTwo = React.createRef()

        this.learnMoreFour = React.createRef();
        this.fourContainerTwo = React.createRef()

        this.learnMoreFive = React.createRef();
        this.fiveContainerTwo = React.createRef();

        this.learnMoreSix = React.createRef();
        this.sixContainerTwo = React.createRef();
    }
    componentDidUpdate() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
   
    toggleSecond = () => {
        this.twoContainerTwo.current.classList.toggle(c.show_one_container_two)
        this.learnMoreTwo.current.classList.toggle(c.rotate_learn_more)
    }
    toggleThree = () => {
        this.threeContainerTwo.current.classList.toggle(c.show_one_container_two)
        this.learnMoreThree.current.classList.toggle(c.rotate_learn_more)
    }
    toggleFour = () => {
        this.fourContainerTwo.current.classList.toggle(c.show_one_container_two)
        this.learnMoreFour.current.classList.toggle(c.rotate_learn_more)
    }
    toggleFive = () => {
        this.fiveContainerTwo.current.classList.toggle(c.show_one_container_two)
        this.learnMoreFive.current.classList.toggle(c.rotate_learn_more)
    }
    toggleSix = () => {
        this.sixContainerTwo.current.classList.toggle(c.show_one_container_two)
        this.learnMoreSix.current.classList.toggle(c.rotate_learn_more)
    }
    render() {

        return(
            <div className = {c.main} id = 'home'>
                <Helmet>
                    <meta name="description" content="Learn more about Sthree Kendra's addiction treatment programs and continuum of care here."/>
                </Helmet>
                <header>
                    <h3>Our Programs</h3>
                </header>
                <div className = {c.container}>
                <div className = {c.one_main_container} id = 'inpatient-addiction-rehab'>
                    <h3>Inpatient Drug Rehab</h3>
                    <div className = {c.one_container} >
                       
                       
                            <div   className = {`${c.one_container_two} ${c.show_one_container_two}`} ref = {this.sixContainerTwo}>
                                <p>
                                At Sthree Kendra, inpatient treatment for substance abuse begins with our clinicians getting a good understanding of your specific situation. Our treatment team will evaluate your medical health, mental health and chemical use history in order to design an individualized drug and alcohol rehab plan for you. With your permission, our rehab staff may also talk with your family members and consult with professionals you might already be working with to address your needs and challenges.
                                </p>
                                <p>Because addiction is a disease that affects your body, mind and spirit, we bring a multidisciplinary team together to provide you with a holistic healing plan. Your licensed team members for residential treatment may include:</p>
                                <ul>
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Physicians </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Nurses </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Psychiatrists </li>   
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Licensed addiction counselors </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Nutritionists </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i> Wellness and fitness specialists </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Continuing care coordinators </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Financial advocates </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Clinical case managers </li>  
                                </ul>
                            </div>
                            <div className = {c.one_container_one}>
                                <img src = {OneImg} alt = ''></img>
                                <p>
                                Inpatient treatment, also referred to as residential treatment, provides the highest level of rehab services for patients diagnosed with alcohol or other drug addiction. Typically, inpatient drug rehab programs include medical detox and integrated mental health services.
                                </p>
                                <div className = {c.learn_more} onClick = {this.toggleSix} ref = {this.learnMoreSix}>Learn More  <i className="fas fa-caret-down"></i></div>
                            </div>
                    </div>
                    </div>



                    <div className = {c.one_main_container} id = '12-step-immersion'>
                    <h3>12-Step Immersion</h3>
                    <div className = {`${c.one_container} ${c.two_container}`}>
                            <div className = {c.one_container_one}>
                                <img src = {TwoImg} alt = ''></img>
                                <p>
                                In its most traditional sense, the 12-Step program gives participants the tools they need to lead sober lives, but without proper training that teaches participants how to implement those tools into their lives, how can you expect someone to make a successful recovery?
                                </p>
                                <div className = {c.learn_more} onClick = {this.toggleSecond}  ref = {this.learnMoreTwo}>Learn More <i className="fas fa-caret-down"></i> </div>
                            </div>
                            <div  className = {`${c.one_container_two} ${c.show_one_container_two}`} ref = {this.twoContainerTwo}>
                                <h4>Applying Immersion’s 12 Steps</h4>
                                <ul>
                                        <li> <i className="fas fa-long-arrow-alt-right"></i>
                                        <p>We admitted we were powerless over alcohol—that our lives had become unmanageable.</p>
                                        </li>  
                                        <li> <i className="fas fa-long-arrow-alt-right"></i> <p>Came to believe that a power greater than ourselves could restore us to sanity. </p></li>  
                                        <li> <i className="fas fa-long-arrow-alt-right"></i> <p>Made a decision to turn our will and our lives over to the care of God as we understood Him.</p> </li>   
                                        <li> <i className="fas fa-long-arrow-alt-right"></i> <p>Licensed addiction counselors.</p></li>  
                                        <li> <i className="fas fa-long-arrow-alt-right"></i> <p>Made a searching and fearless moral inventory of ourselves. </p></li>  
                                        <li> <i className="fas fa-long-arrow-alt-right"></i>  <p>Admitted to God, to ourselves, and to another human being the exact nature of our wrongs.</p> </li>  
                                        <li> <i className="fas fa-long-arrow-alt-right"></i> <p>Were entirely ready to have God remove all these defects of character.</p></li>  
                                        <li> <i className="fas fa-long-arrow-alt-right"></i> <p>Humbly asked Him to remove our shortcomings.</p> </li>  
                                        <li> <i className="fas fa-long-arrow-alt-right"></i> <p>Made a list of all persons we had harmed, and became willing to make amends to them all.</p> </li>  
                                </ul>
                            </div>
                    </div>
                    </div>


                    
                    <div className = {c.one_main_container} id = 'spiritual-coaching'>
                    <h3>Spiritual Coaching </h3>
                    <div className = {c.one_container}>
                         
                            <div  className = {`${c.one_container_two} ${c.show_one_container_two}`} ref = {this.threeContainerTwo}>
                                <p>
                                Holistic wellness is the root of Sthree Kendra treatment philosophy. We want our clients to become happy, healthy and fulfilled in all areas of their lives, which is why our treatment plans focus so heavily on strengthening the mind, body, spirit connection.
                                </p>
                                <p>
                                A spiritual coach uses a deeper, more holistic approach. They work with you on the operating system beneath your consciousness. I’m sure you’ve seen the human psyche descried as the iceberg floating in the water; a small portion of us showing to the world and a great big portion of us under the water hiding below the surface. A spiritual coach helps you go deep into the part of your iceberg that is below the waterline. When you are able to understand, work with, change and utilize this portion of your operating system, you are able to change your life.
                                </p>
                                <h4>Benefits of Spiritual Coaching</h4>
                                <ul>
                                
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Provides Clarity and Direction </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Helps with Goal Setting </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Provides Unbiased Feedback and Support </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Holds You Accountable </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Don’t take anything personally </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Always do your best </li>  
                                </ul>
                            </div>
                            <div className = {c.one_container_one}>
                                <img src = {ThreeImg} alt = ''></img>
                                <p>
                                A spiritual coach is someone who helps you connect to who you truly are. They work with you to change/re-direct/navigate your life, uncover your desires, take steps towards your goals, achieve your dreams, bust limiting beliefs, and remove roadblocks.
                                </p>
                                <div className = {c.learn_more} onClick = {this.toggleThree}  ref  = {this.learnMoreThree}>Learn More  <i className="fas fa-caret-down"></i></div>
                            </div>
                    </div>
                    </div>

                    <div className = {c.one_main_container} id = 'life-skills'>

                    <h3>Life Skills</h3>
                    <div className = {`${c.one_container} ${c.two_container}`}>
                       
                            <div className = {c.one_container_one}>
                                <img src = {FourImg} alt = ''></img>
                                <p>
                                    Addiction gets in the way of personal growth. Establish independence by learning key life skills, like organization, time management, and financial wellness.
                                </p>
                                <div className = {c.learn_more}  onClick = {this.toggleFour}  ref = {this.learnMoreFour}>Learn More  <i className="fas fa-caret-down"></i></div>
                            </div>
                            <div  className = {`${c.one_container_two} ${c.show_one_container_two}`} ref = {this.fourContainerTwo}>
                                <p>
                                When people start using and abusing drugs or alcohol, especially at a young age, they often miss out on reaching certain milestones and learning some of life’s most important lessons. In the treatment community, it is widely believed that early substance abuse stunts emotional maturity. When addiction is in control, parents, educators and other role models lose their authority and ability to influence in positive, nurturing ways.
                                </p>
                                <p>People struggling with addiction and alcoholism may get older, but they don’t “grow up.” Our Life Skills Program is designed to equip clients with the skills necessary to navigate through life successfully. We provide training in:</p>
                                <ul>
                                     <li> <i className="fas fa-long-arrow-alt-right"></i> Goal setting and accountability </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Financial responsibility </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Physical health and wellness </li>   
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Interpersonal communication and healthy relationships </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Community service projects </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i> How to be organized and stay focused </li>  
                                     <li> <i className="fas fa-long-arrow-alt-right"></i>Understanding time management so stress does not overwhelm us </li>  

                                </ul>
                            </div>
                    </div>
                    </div>


                    <div className = {c.one_main_container} id = 'food-nutrition'>
                    <h3>Food & Nutrition</h3>
                    <div className = {c.one_container}>

                      
                       <div  className = {`${c.one_container_two} ${c.show_one_container_two}`} ref = {this.fiveContainerTwo}>
                           <p>
                           Nutrition is a critical part of health and development. Better nutrition is related to improved infant, child and maternal health, stronger immune systems, safer pregnancy and childbirth, lower risk of non-communicable diseases (such as diabetes and cardiovascular disease), and longevity.
                           </p>
                           <p>At Sthree Kendra, we believe that nutrition plays an integral role in recovery. Using sustainable and fresh ingredients, our menus are crafted to replenish the body of vital nutrients that are lost during substance abuse. We use a from-scratch approach with our meals and utilize local and seasonal product when available. A goal with our food & nutrition program is to restore healthy eating habits through education and meal planning.</p>
                
                           <ul>
                                <li> <i className="fas fa-long-arrow-alt-right"></i> Goal setting and accountability </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Financial responsibility </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Physical health and wellness </li>   
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Interpersonal communication and healthy relationships </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Community service projects </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i> How to be organized and stay focused </li>  
                                <li> <i className="fas fa-long-arrow-alt-right"></i>Understanding time management so stress does not overwhelm us </li>  

                           </ul>
                       </div>
                       <div className = {c.one_container_one}>
                           <img src = {FiveImg} alt = ''></img>
                           <p>
                           Malnutrition is a major side effect of addiction. Once you enter treatment, you should follow a diet that includes healthy, vitamin-packed foods. At Sthree Kendra, our team of experts will ensure you receive the proper nutrition needed to begin your recovery.
                           </p>
                           <div className = {c.learn_more} onClick = {this.toggleFive} ref   = {this.learnMoreFive}>Learn More <i className="fas fa-caret-down"></i></div>
                       </div>
               </div>
                    </div>


                </div>
            </div>
        )
    }
}


export default ProgramsPage;
