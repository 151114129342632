import React from 'react'
import c from './style.module.css'
import Logo from '../../../Images/logosthreekendra.png'
import './ToggleStyle.css'
import { Link } from 'react-router-dom';
class MobileView extends React.Component{
    constructor(props) {
        super(props);
        this.toggleRef = React.createRef();
        this.navbarRef = React.createRef();
        this.cancelRef = React.createRef()

        //next
        this.caretOneRef = React.createRef();
        this.dropdownOneRef = React.createRef();

        this.caretTwoRef = React.createRef();
        this.dropdownTwoRef = React.createRef();

        this.carteThreeRef = React.createRef();
        this.dropdownThreeRef = React.createRef();

        this.carteFourRef = React.createRef();
        this.dropdownFourRef = React.createRef()
        
    }
    componentDidMount() {
        this.toggleRef.current.addEventListener('click', () => {
            this.navbarRef.current.classList.add("show_navbar");
        })
        this.cancelRef.current.addEventListener('click', () => {    
            this.navbarRef.current.classList.remove("show_navbar");
        })

        this.caretOneRef.current.addEventListener('click', () => {
            this.caretOneRef.current.classList.toggle("rotate_caret");
            this.dropdownOneRef.current.classList.toggle('show_dropdown_one')
        })

        this.caretTwoRef.current.addEventListener('click', () => {
            this.caretTwoRef.current.classList.toggle("rotate_caret");
            this.dropdownTwoRef.current.classList.toggle('show_dropdown_one')
        })
        this.carteThreeRef.current.addEventListener('click', () => {
            this.carteThreeRef.current.classList.toggle("rotate_caret");
            this.dropdownThreeRef.current.classList.toggle('show_dropdown_one')
        })

        this.carteFourRef.current.addEventListener('click', () => {
            this.carteThreeRef.current.classList.toggle("rotate_caret");
            this.dropdownFourRef.current.classList.toggle('show_dropdown_one')
        })
        
    }
    onClick  = () => {
        this.navbarRef.current.classList.remove("show_navbar");
    }
    render() {
        return(
            <section className = {c.main}>
                <section className = {c.container}>
                    <div className = {c.logo}>
                        <Link to = '/'>
                            <img src = {Logo} alt = 'Sthree Kendra'></img>
                            <h3 className = {c.title}>Sthree Kendra</h3>
                        </Link>

                    </div>
                    <div className = {c.hamburger} ref = {this.toggleRef}>
                        <i className="fas fa-bars"></i>
                    </div>
                </section>
                <nav className = {c.navbar} ref = {this.navbarRef}>
                    <section className = {c.navcontainer}>
                        <div className = {c.logo}>
                         <Link to = '/'>
                            <img src = {Logo} alt = 'Sthree Kendra'></img>
                            <h3 className = {c.title}>Sthree Kendra</h3>
                         </Link>
                        </div>
                        <div className = {c.hamburger} ref = {this.cancelRef}>
                            <i className="fas fa-times"></i>
                        </div>
                    </section>
                    <div className = {c.navlist}>

                        <li>
                        <div className  = {c.main_li}>
                            <Link to = '/addiction/home ' onClick = {this.onClick}>Addiction</Link>
                            <i className="fas fa-caret-down" ref = {this.caretOneRef}> </i>
                        </div>
                        <div className = {c.dropdown_one} ref = {this.dropdownOneRef}>
                                <div><Link to = '/addiction/what-is-adddiction' onClick = {this.onClick}>What is Addiction?</Link></div>
                                <div><Link to = '/typesofaddiction/home' onClick = {this.onClick}>Types of Addiction</Link></div>
                            </div>
                        </li>



                        <li>
                        <div className  = {c.main_li}>
                            <Link to = '/treatments/home' onClick = {this.onClick}>Treatment</Link>
                            <i className="fas fa-caret-down" ref = {this.caretTwoRef}> </i>
                        </div>
                        <div className = {c.dropdown_one} ref = {this.dropdownTwoRef}>

                                <div><Link to = '/programs/home' onClick = {this.onClick}>Our Programs</Link></div>
                                <div><Link to = '/treatments/home' onClick = {this.onClick}>Our Treatments</Link></div>
                            </div>
                        </li>

                        
                        <li>
                        <div className  = {c.main_li}>
                            <Link to = '/psy/home' onClick = {this.onClick}>Psychiatry</Link>
                            <i className="fas fa-caret-down" ref = {this.carteThreeRef}> </i>
                        </div>
                        <div className = {c.dropdown_one} ref = {this.dropdownThreeRef}>
                                <div><Link onClick = {this.onClick} to = '/psy/home'>Mental Disorders</Link></div>
                                
                        </div>
                        </li>
                        
                        <li>
                        <div className  = {c.main_li}>
                            <Link to = '/about/home' onClick = {this.onClick}>About Us</Link>
                            <i className="fas fa-caret-down" ref = {this.carteFourRef}> </i>
                        </div>
                        <div className = {c.dropdown_one} ref = {this.dropdownFourRef}>
                                <div><Link onClick = {this.onClick} to = '/faq'>FAQ'S</Link></div>
                                <div><Link onClick = {this.onClick} to = '/our-facility'>Our Campus</Link></div>
                                <div><Link onClick = {this.onClick} to = '/about/why-sthree-kendra'>Why Sthree Kendra?</Link></div>
                        </div>
                        </li>
                      
                        <li className = {c.contact_btn}><Link onClick = {this.onClick} to = '/contact/contact'>Contact Us</Link></li>
                    </div>
                </nav>
            </section>
        )
    }
}


export default MobileView;