import React from 'react'
import c from './style.module.css'
import LogoImg from '../../../Images/logosthreekendra.png'
//dropdowns

import DropdownOne from './DropdownOne'
import DropdownTwo from './DropdownTwo';
import DropdownThree from './DropdownThree';
import { Link } from 'react-router-dom';
import DropdownFour from './DropdownFour'
class DesktopView extends React.Component{
    constructor(props) {
        super(props);
        this.DropdownOneRef = React.createRef();
        this.DropdownTwoRef = React.createRef();
        this.DropdownThreeRef = React.createRef();
        this.DropdownFourRef = React.createRef();

        this.NavItemOneRef = React.createRef();
        this.NavItemTwoRef = React.createRef();
        this.NavItemThreeRef = React.createRef();
        this.NavItemFourRef = React.createRef();

        this.navbar_scroll = React.createRef();
    }
    componentDidMount() {   
        //dropdown one event
        this.NavItemOneRef.current.addEventListener('mouseover', () => {
            this.DropdownOneRef.current.classList.add('show_dropdown_one')
        })
        this.NavItemOneRef.current.addEventListener('mouseout', () => {
            this.DropdownOneRef.current.classList.remove('show_dropdown_one')
        })



        this.NavItemTwoRef.current.addEventListener('mouseover', () => {
            this.DropdownTwoRef.current.classList.add('show_dropdown_two')
        })
        this.NavItemTwoRef.current.addEventListener('mouseout', () => {
            this.DropdownTwoRef.current.classList.remove('show_dropdown_two')
        })



        this.NavItemThreeRef.current.addEventListener('mouseover', () => {
            this.DropdownThreeRef.current.classList.add('show_dropdown_three')
        })
        this.NavItemThreeRef.current.addEventListener('mouseout', () => {
            this.DropdownThreeRef.current.classList.remove('show_dropdown_three')
        })



        this.NavItemFourRef.current.addEventListener('mouseover', () => {
            this.DropdownFourRef.current.classList.add('show_dropdown_four')
        })
        this.NavItemFourRef.current.addEventListener('mouseout', () => {
            this.DropdownFourRef.current.classList.remove('show_dropdown_four')
        })
      
       
    }
   
    closeNavBarOne = () => {
        this.DropdownOneRef.current.classList.remove('show_dropdown_one')
    }

    closeNavBarTwo = () => {
        this.DropdownTwoRef.current.classList.remove('show_dropdown_two')
    }    

    closeNavBarThree = () => {
        this.DropdownThreeRef.current.classList.remove('show_dropdown_three')
    }    

    closeNavBarFour = () => {
        this.DropdownFourRef.current.classList.remove('show_dropdown_four')
    }    

    render() {
        return(
            <div className = {c.container}>
                <section className = {c.header}>
                    <div className = {c.callus}>
                        <a href = 'tel:+918884730316' >  Call us: +91 8884730316 <i className="fas fa-phone-alt"></i></a>
                    </div>
                    <div className = {c.links}>
                        <a href = 'https://www.instagram.com/sthreekendra/' target="_blank"> <i className="fab fa-instagram"></i></a>
                        <a href = 'https://api.whatsapp.com/send?phone=+918884730316' target="_blank"><i className="fab fa-whatsapp"></i></a>
                    </div>
                    <div className = {c.location}>
                        <a href = 'https://goo.gl/maps/2rHKJxpDCcKUHxeL9' target="_blank">Location <i className="fas fa-map-marker-alt"></i></a>
                    </div>
                </section>
              
                <nav className = {c.navbar_main} ref = {this.navbar_scroll}>
                    <header className = {c.navbar}>
                      
                       <div className = {c.logo}>
                       <Link to = '/'>
                            <img src = {LogoImg} alt = 'Sthree Kendra'></img>
                            <h2>Sthree Kendra</h2>
                            </Link> 
                        </div> 
                       
                        <div className = {c.navlist}>
                            <div className = {c.item}  ref = {this.NavItemOneRef}>
                                <li onClick = {this.closeNavBarOne} className = {c.main_li}><Link to = '/addiction/home'>Addiction</Link></li>
                                <DropdownOne Propref = {this.DropdownOneRef}></DropdownOne>
                            </div>
                            <div  ref = {this.NavItemTwoRef}>
                                <li onClick = {this.closeNavBarTwo} className = {c.main_li} ><Link to = '/treatments/sthreekendra'>Treatment</Link></li>
                                <DropdownTwo Propref = {this.DropdownTwoRef}></DropdownTwo>
                            </div>
                            <div ref = {this.NavItemThreeRef}> 
                                <li onClick = {this.closeNavBarThree} className = {c.main_li}  ><Link to = '/psy/home'>Psychiatry</Link></li>
                                <DropdownThree Propref = {this.DropdownThreeRef}></DropdownThree>
                            </div>
                            <div ref = {this.NavItemFourRef}> 
                                <li onClick = {this.closeNavBarFour} className = {c.main_li} ><Link to = '/about/home'>About Us</Link></li>
                                <DropdownFour Propref = {this.DropdownFourRef}></DropdownFour>
                            </div>
                            
                            <li  className = {c.contact_btn}><Link to = '/contact/home'>Contact Us</Link></li>
                        </div>
                    </header>
                </nav>
               
                
               
               
            </div>
        )
    }
}


export default DesktopView;