import React from 'react'
import c from  './Footer.module.css'
import Logo from '../../Images/logosthreekendra.png'
import Axios from 'axios'
class Footer extends React.Component{
    state = {msg:''}
    emailRef = React.createRef();
    onclick = () => {
        if (this.emailRef.current.value !== '') {
            Axios.post('https://sthreekendra.herokuapp.com/sendemail', {email:this.emailRef.current.value})
            .then((res) => {
               

            })
            this.setState({msg:'Thank You For Subscribing'})
        } else {
            this.setState({msg:'Please Provide Email'})
        }
        setTimeout(() => {
            this.setState({msg:''})
            this.emailRef.current.value = '';
        },6000)
    }
    render() {

        return(
           
               <div className ={c.footer_container}>
                    <div className ={c.footer}>
                        <div className ={c.footer_title_div}>
                            <img src={Logo}/>
                            <div className ={c.footer_title}>
                                Sthree Kendra
                            </div>
                        </div>

                        <div className ={c.footer_info_div}>
                            <div className ={c.address}>
                                <p className ={c.footer_bold}>Address</p>
                                <p>6, M.E.I. Layout, Bagalagunte, Hesaraghatta Main Road, Bengaluru-560073</p>
                            </div>
                            <div className ={c.phone}>
                                <p className ={c.footer_bold}>Phone</p>
                                <p>+91 888-473-0316 </p>
                            </div>
                        </div>
                        <div className ={c.newsletter}>
                            <div className ={c.newsletter_header}>
                                <h2> Newsletter</h2>
                                <p>Signup to our newsletter and stay up to date</p>
                            </div>
                        
                            <div className ={c.input_form}>
                                <input type = 'text' placeholder="Enter Your Email"  ref = {this.emailRef}/>
                                <button onClick = {this.onclick}>Subscribe</button>
                               <p>{this.state.msg?this.state.msg:'  '}</p>
                            </div>
                        </div>
                </div>
                <div className ={c.copyright}>
                    <div>Copyright 2020 © All Rights Reserved.</div>
                    <div>Created By Aanista
                    </div>
                </div> 
        </div>
       
        
        )
    }
}

export default Footer;