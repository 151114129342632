import React from 'react'
import Hero from './Hero'
import MentalHealth from './MentalHealth';
import Programs from './Programs';
import Interval from './Interval';
import Stats from './Stats';
import Leaders from './Leaders';
import Patients from './Patients';
import Location from './Location'
import Follow from './Follow'
import { Helmet } from 'react-helmet';
class Homepage extends React.Component{
    constructor(props) {
        super(props)
        this.hideRef = React.createRef();
    }
    componentDidMount () {
    
    }
    render() {
        return(
            <div>
                <Helmet>
                <meta name="description" content="Find recovery at the premier drug and alcohol addiction treatment center. Sthree Kendra provides world class addiction treatment and mental health center in bengaluru,karnataka"/>
                <meta name="description" content="STHREE KENDRA is a convalescent / Residential half way home for alcohol addiction and drug abuse"/>
                </Helmet>
                <Hero></Hero>
                <MentalHealth></MentalHealth>
                <br></br>
                <Programs></Programs>
                <Interval></Interval>
                <Stats></Stats>
                <Leaders></Leaders>
                <Patients></Patients>
                <Location></Location>
                <Follow></Follow>
            </div>
      
        )
    }
}


export default Homepage;