import React from 'react'
import AppStore from '../../Images/appstore.png'
import PlayStore from '../../Images/playstore.png'
import c  from './Follow.module.css'
function Follow (props) {


    return(
        <div className = {c.f_container}>
            <div className = {c.app_header}>Follow Sthree Kendra</div>
            <div className= {c.social_links}>
                            <div className={c.fb_link}>
                               <a href = 'https://www.instagram.com/sthreekendra/' target="_blank"><i className="fab fa-facebook"></i></a> 
                            </div>
                            <div className={c.wt_link}>
                            <a href = 'https://api.whatsapp.com/send?phone=+918884730316' target="_blank"><i className="fab fa-whatsapp"></i></a> 
                            </div>
                            <div className={c.ig_link}>
                            <a href = 'https://www.instagram.com/sthreekendra/' target="_blank"><i className="fab fa-instagram"></i></a> 
                            </div>
                            <div class={c.tt_link}>
                            <a href = 'https://api.whatsapp.com/send?phone=+918884730316' target="_blank"><i className="fab fa-twitter"></i></a> 
                            </div>
                            <div className={c.ph_link}>
                            <a href = 'tel:+919986510457'><i className="fas fa-phone-square-alt"></i></a> 
                            </div>
            </div>
            <div className = {c.app_header}>Download The Sthree Kendra App</div>
            <div className = {c.app_img}>
               <a href = '/'> <img src = {AppStore} alt = ''/></a> 
               <a href = '/'> <img src = {PlayStore} alt = ''/></a> 

            </div>
        </div>
    )
}


export default Follow;