import React from 'react'
import './MentalHealth.css'
import Img1 from '../../Images/psy1.png'
import Img2 from '../../Images/psy2.png'
import Img3 from '../../Images/psy3.png'
import Psy from '../../Images/psy.jpg'
import { Link } from 'react-router-dom'
class MentalHealth extends React.Component{
    constructor(props) {
        super(props);
      
    }
    componentDidMount() {
      

    }
    render(){
        return(
            <div className = 'm_main'>
                <div className = 'm_container'>
                    <div className = 'm_header'>Psychological Disorders Treatment We Offer</div>
                    <div className = 'm_one'>
                        <div className = 'm_img'>
                            <img src = {Psy} alt ='psy'></img>
                        </div>
                        <div className = 'm_content'>
                            <div className = 'm_content_one'>
                                <header>WHAT WE TREAT</header> 
                                <p>Clinically advanced treatment for all Psychological Disorders.</p>
                            </div>
                            <div className = 'm_content_two'>
                                <div className = 'one_div'>
                                    <img src ={Img1} alt =''></img>
                                    <p>Depression</p>
                                </div>
                                <div className = 'one_div'>
                                    <img src ={Img2} alt =''></img>
                                    <p>Bipolar Disorder</p>
                                </div>
                                <div className = 'one_div'>
                                    <img src ={Img3} alt =''></img>
                                    <p>Schizophrenia</p>
                                </div>
                            </div>
                            <Link to = '/psy/home'><button>View More</button>  </Link>
                        </div>
                    </div>
                </div>
            </div>
        )       
    }
}



export default MentalHealth;