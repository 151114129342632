import React from 'react'
import c from './style.module.css'
import GroupImg from '../../Images/group.jpg'

class Hero extends React.Component{
    constructor(props) {
        super(props);
        this.elementOne = React.createRef();
        this.elementTwo = React.createRef();
    }
    componentDidMount() {
      
    }
    render() {
        return(
            <div className = {c.main}>
                 
                    <div className = {c.container}>
                        <div className = {c.one} ref = {this.elementOne}>
                            <div className = {c.main_info}>WORLD CLASS ADDICTION TREATMENT AND MENTAL HEALTH CENTER IN BENGALURU,KARNATAKA</div>
                            <div className = {c.sub_info}>Sthree Kendra For <span className = {c.ladies}>Ladies</span> Only.</div>

                            <button>
                               <a href = 'tel:+91 8884730316'>+91 8884730316</a> </button>
                        </div>
                        <section className = {c.two} ref = {this.elementTwo}>
                                <img src = {GroupImg}></img>
                        </section>
                    </div>
            </div>
        )
    }
}


export default Hero;