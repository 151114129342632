import React from 'react'
import c from './Profile.module.css'
import Axios from 'axios'
function SearchResult(props) {
   console.log(props)
    return(
        <div className = {c.email} >
            <p onClick = {props.onEmailClick(props.email)}>email: {props.email}</p>
        </div>
    )
}
class Profile extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            subs:0,
            key:'',
            emails:[]
        }
        this.subjectRef = React.createRef()
        this.msgHeader = React.createRef()
        this.msg = React.createRef()
        this.aftermsgRef = React.createRef();
    }
    componentDidMount () {
        if (!localStorage.getItem('email')) {
            this.props.history.push('/login')
        } else {
            Axios.get('https://sthreekendra.herokuapp.com/getsub')
                .then(res => {
                    this.setState({
                        subs:res.data.subs
                    })
                })
        }
    }
    onLogOut = (e) => {
        localStorage.removeItem('email')
        this.props.history.push('/login')
    }
    onSubmit = (e) => {
        e.preventDefault();
        const subject = this.subjectRef.current.value;
        const msgHeader = this.msgHeader.current.value;
        const msg = this.msg.current.value;
        this.aftermsgRef.current.classList.add(c.aftermsg_show)

        setTimeout(()=> {
            this.aftermsgRef.current.classList.remove(c.aftermsg_show);
            this.msg.current.value = '';
            this.subjectRef.current.value = '';
            this.msgHeader.current.value = '';
        },6000)
        Axios.post("https://sthreekendra.herokuapp.com/send", {subject,msgHeader,msg})
        .then(res => {
     
            
        })
    }
    render() {
        return(
            <div className = {c.main}>
                    <header>
                        <h3 className = {c.admin}>Admin Divij</h3>
                    </header>
                    
                    <div className = {c.container}>
                        <div className = {c.one}>
                            <h3>Total Email Subscribed</h3>
                            <h4>{this.state.subs} email</h4>
                        </div>
                        <div className = {c.two}>
                            <div className = {c.three}>
                                    <div className = {c.three_one}>
                                        <h3>Send Email</h3>
                                        <p>Email Will Sent to Every Subscriber</p>
                                    </div>
                                    <div className = {c.three_two}>
                                        <button className = {c.logout} onClick = {this.onLogOut}>Logout</button>
                                    </div>
                            </div>


                            <form className = {c.form} onSubmit = {this.onSubmit}>
                                    <label>Subject</label><br></br>
                                    <input type = 'text'  ref = {this.subjectRef}></input><br></br>
                                    <label>Message Header</label><br></br>
                                    <input type = 'text' ref = {this.msgHeader}></input><br></br>
                                    <label>Message</label><br></br>
                                    <input type = 'text' ref = {this.msg}></input><br></br>
                                    <button type = 'submit'>Send</button>
                            </form>
                            <p className = {c.aftermsg} ref = {this.aftermsgRef}>Email Sent!</p>
                        </div>
                    </div>

            </div>
        )
    }
}

export default Profile;