import React from 'react'
import ReactDOM from 'react-dom'
import NavBar from './Components/NavBar/NavBar'
import Homepage from './Components/Homepage/Homepage'
import Footer from './Components/Footer/Footer'
import AppRouter from './AppRouter'
const App = function() {
    return  (
        <div>
                <AppRouter></AppRouter>
        </div>
    )
}

ReactDOM.render(<React.StrictMode>
    <App/>
</React.StrictMode>, document.getElementById('root'))