import React from 'react'
import c from './TypesOfAddiction.module.css'
import Adderall from './images/addreall.jpg'
import AlcoholImg from './images/alcohal.png'
import CocaineImg from './images/cocain.jpg'
import HeroinImg from './images/heroin.jpg'
import GanjaImg from './images/ganja.jpg'
import MethImg from './images/meth.jpg'
import { Helmet } from 'react-helmet'


class TypesOfAddiction extends React.Component{
    componentDidUpdate() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id
        let element = document.getElementById(id)
        if (element) {
            element.scrollIntoView();
        }
    }
    render() {
        return(
            <div className = {c.container}>
                <Helmet>
                <meta name="keywords" content="types of addiction"/>
                <meta name="description" content="There are two main types of addiction, each with several potential treatment options. Learn how addiction generally works, how it can manifest through substance use and compulsive behaviors, and the available treatments."/>
                </Helmet>
                <header id = 'home'>
                        <div className = {c.title}>Types Of Addiction</div>
                </header>
                <div className = {c.one_container}>
                    <div className = {c.one}>
                        <p>
                        The terms "addiction," "chemical dependency," "substance abuse," and "substance use disorder" tend to be used interchangeably in reference to the compulsive use of alcohol, over-the-counter medications, prescribed drugs, illegal drugs, or other mood-altering substances despite harmful consequences. Doctors, treatment providers, other healthcare professionals, and insurance companies typically use the term "substance use disorder" to describe addiction to alcohol and other drugs.
                        </p>
                        <p>Like other chronic diseases such as diabetes, asthma and hypertension, addiction often involves cycles of relapse and remission. And like other chronic diseases, addiction can be effectively treated and managed successfully.</p>
                    </div>
                </div>

                <div className = {c.two_container} id = 'Addreall'>
                    <h3>Adderall Addiction</h3>
                    <div className = {c.two_wraper}> 
                    <div className = {c.two_container_two}>
                        <p>
                            Adderall is an addictive prescription stimulant with effects similar to meth. Although not everyone who uses Adderall will develop an addiction, people regularly taking Adderall at unprescribed doses are at a high risk of becoming addicted.
                        </p>       
                        <p>Adderall works by increasing dopamine and norepinephrine levels in the central nervous system. Norepinephrine affects how the brain responds to events, particularly how it pays attention and the speed at which it reacts to outside stimuli. Dopamine, the body’s “feel good” chemical, creates a rewarding effect. Although dopamine occurs naturally, drugs like Adderall produce unnaturally high levels of it. This can cause users to come back for more.</p>   

               
                    </div>
                    <div className = {c.two_container_one}>
                        <img src = {Adderall} alt = ''></img>
                    </div>
                    </div>
                    <div className = {c.two_list}>
                        <h3>Signs of an Adderall Overdose</h3>
                        <ul>
                            <li> Vomiting</li>
                            <li> Rapid breathing</li>
                            <li> Stomach pain</li>
                            <li> Headaches</li>
                            <li> Hallucinations</li>
                            <li> Heart attack</li>
                        </ul>
                    </div>               
                </div>



                <div className = {c.two_container_main} id = 'Alcohol'>  
                    <div className = {c.two_container}>
                        <h3>Alcohol Addiction</h3>
                        <div className = {c.two_wraper}>
                        <div className = {c.two_container_one}>
                            <img src = {AlcoholImg} alt = ''></img>
                        </div>
                        <div className = {c.two_container_two}>
                            <p>Alcohol is the most used intoxicating substance in the India. Drinking is legal for people age 21 and older, and most who drink do so without incident. However, there is a continuum of risks and problems associated with alcohol consumption.</p>
                            <p>Risky drinking involves heavy or excessive drinking, including binge drinking. Binge drinking is defined as four or more drinks on a single occasion for females and five or more drinks for males. Risky drinking also refers to consuming alcohol in situations that involve an increased potential for harm, such as before or while driving, while pregnant, or while taking certain prescription medications (e.g., certain sedatives).</p>
                        </div>
                        </div>
                        <div className = {c.two_list}>
                            <h3>Warning Signs of Alcoholism</h3>
                            <ul>   
                                <li> Tremors</li>
                                <li> Sweating</li>
                                <li> High pulse rate</li>
                                <li> Nausea or vomiting</li>
                                <li>Insomnia</li>
                                <li> Anxiety or depression</li>
                            </ul>
                        </div>
                   
                    </div>
                </div>


                <div className = {c.two_container} id = 'Cocaine'>
                    <h3>Cocaine Addiction</h3>
                    <div className = {c.two_wraper}>
                    
                    <div className = {c.two_container_one}>
                        <img src = {CocaineImg} alt = ''></img>
                    </div>
                    <div className = {c.two_container_two}>
                        <p>Prolonged use of cocaine endangers your physical, mental and emotional health, and can harm your relationships, finances and work performance. Knowing the signs, symptoms and serious side effects of cocaine use, misuse and addiction can help you or a loved one understand what’s at risk and when to seek professional help.</p>
                        <p>Cocaine is a serotonin/norepinephrine/dopamine reuptake inhibitor, which means the substance helps to release "feel-good" chemicals in the brain. Over time, the brain receptors may become desensitized to the manufactured rush of dopamine that cocaine causes. According to the National Institute on Drug Abuse, "As with other drugs, repeated use of cocaine can cause long-term changes in the brain’s reward circuit and other brain systems, which may lead to addiction. </p>
                    </div>
                    </div>
                    <div className = {c.two_list}>
                            <h3>Signs of Cocaine Overdose</h3>
                            <ul>
                                <li> An elevated heart rate </li>
                                <li> High blood pressure</li>
                                <li> Feeling shaky</li>
                                <li> Strokes</li>
                                <li> Heart attacks</li>
                                <li> Extreme anxiety</li>
                            </ul>
                    </div>
                   
                </div>

            

                <div className = {c.two_container_main} id = 'Alcohol'>

                            <div className = {c.two_container}>
                                <h3>Opioid and Heroin Addiction</h3>
                                <div className = {c.two_wraper}>

                                <div className = {c.two_container_one}>
                                    <img src = {HeroinImg} alt = ''></img>
                                </div>
                                <div className = {c.two_container_two}>
                                <p>Pure heroin (diacetylmorphine) is a white powder with a bitter taste abused for its euphoric effects. Heroin, a highly addictive drug, is derived from the morphine alkaloid found in opium poppy plant (Papaver somniferum) and is roughly 2 to 3 times more potent than morphine. It is usually injected, smoked or snorted up the nose. It exhibits euphoric ("rush"), anti-anxiety and pain-relieving properties.</p>
                                <p>Physicians often prescribe opioid medications to relieve acute pain—from injuries, surgeries, toothaches, or other medical and dental procedures—or to alleviate chronic pain. However, studies show that long-term opioid use for chronic pain can be ineffective—and comes with the risk of addiction. You might recognize some of the more well-known opioid drugs prescribed for pain,</p>
                                </div>
                                </div>
                                <div className = {c.two_list}>
                                        <h3>Side Effects of Heroin/Opioid</h3>
                                        <ul>
                                            <li> Drowsiness or coma </li>
                                            <li> Impaired mental functioning</li>
                                            <li> Slurred speech</li>
                                            <li> Depression</li>
                                            <li> Risk of HIV, hepatitis B or C</li>
                                            <li> Loss of libido, sexual dysfunction</li>
                                        </ul>
                                </div>
                            </div>
                </div>

           




                <div className = {c.two_container} id = 'Marijuana'>
                    <h3>Marijuana Addiction</h3>
                    <div className = {c.two_wraper}>
                    <div className = {c.two_container_one}>
                        <img src = {GanjaImg} alt = ''></img>
                    </div>
                    <div className = {c.two_container_two}>
                    <p>When marijuana is smoked, THC passes rapidly from the lungs into the bloodstream, which carries the substance to the brain and other organs throughout the body. THC is absorbed more slowly when ingested through food or drink.
                            </p>
                            <p>Regardless of how THC is ingested, the substance acts specifically on the brain cell’s cannabinoid receptors. These receptors—ordinarily activated by THC-like chemicals produced naturally by the body—are part of the neural communication network, called the endocannabinoid system, which plays an important role in normal brain development and function.</p>   
                           
                    </div>
                    </div>
                    <div className = {c.two_list}>
                            <h3>Health Risks</h3>
                            <ul>
                                    <li> Daily cough</li>
                                    <li> Rapid breathing</li>
                                    <li> Heightened risk of lung infections</li>
                                    <li> Increased heart rate</li>
                                    <li> Damage to the immune system</li>
                                    <li> Headaches</li>
                            </ul>
                    </div>
                </div>


                
                <div className = {c.two_container_main} id = 'Meth'>
                        
                            <div className = {c.two_container}>
                                <h3>Methamphetamine (Meth) Addiction</h3>
                                <div className = {c.two_wraper}>
                                <div className = {c.two_container_one}>
                                     <img src = {MethImg} alt = ''></img>
                                </div>
                                <div className = {c.two_container_two}>
                                    <p>Crystal methamphetamine is a synthetic psychostimulant drug that speeds up the inner working of the brain and affects the central nervous system, with long-lasting effects on the body. Nicknames include crystal, speed, zoom, go, crank, tweak, ice, glass, rock candy, shizzo, yaba, fire, uppers, poor man's cocaine or shards. Some people call it Tina when out in public, because it sounds like they're talking about a girl rather than a drug.</p>
                                    <p>Crystal methamphetamine can be swallowed as a pill, injected intravenously, snorted or smoked, and can either look like little shards of glass or an odorless, bitter-tasting powder, ranging in color from clear to pink, or off-white to brown, depending on the ingredients used to make it. The drug has devastating effects on those who become dependent.</p>      
                                </div>
                                </div>
                                <div className = {c.two_list}>
                                    <h3>Side effects of meth addiction</h3>
                                    <ul>   
                                        <li> Decreased appetite</li>
                                        <li> Psychotic episodes</li>
                                        <li> Euphoria</li>
                                        <li> Anxiety</li>
                                        <li> Risk of HIV/AIDS</li>
                                        <li> Incessant itching</li>
                                    </ul>
                                </div>
                            </div>
                </div>



                <div className = {c.eight_container} id = 'Other'>
                            <div className = {c.header1}>Other Types of Addictions and Compulsive Behavior Problems</div>
                            <div className = {c.eight_container_body}>
                                    <div className = {c.eight_container_one}>
                                        <h3>Exercise Addiction</h3>
                                        <p> Exercising too frequently, to the point where it is damaging and injuring the body, but one continues to exercise and not rest.</p>
                                    </div>
                                    <div className = {c.eight_container_two}>
                                        <h3>Food Addiction</h3>
                                        <p>Overeating or under eating to the extreme detriment of one’s health in terms of both mind and body.</p>
                                    </div>
                                    <div className = {c.eight_container_three}>
                                        <h3>Body Dysmorphic Disorder</h3>
                                        <p>Becoming obsessed with plastic surgery, tanning, or exercise in the quest for perfection. People with Body Dysmorphic Disorder are typically preoccupied with perceived physical defects.</p>
                                    </div>
                                    <div className = {c.eight_container_four}>
                                        <h3>Gambling Addiction</h3>
                                        <p> Risking something of value in the hopes of gaining something of even greater value, to the detriment of yourself and loved ones, and being unable to stop.</p>   
                                    </div>
                                    <div className = {c.eight_container_five}>
                                        <h3>Self-harm Addiction</h3>
                                        <p>Self-harming typically starts out as an impulse, but can quickly turn into a compulsive behavior used to numb emotional pain by causing physical pain.</p>
                                    </div>
                                    <div className = {c.eight_container_six}>
                                        <h3>Sex Addiction</h3>
                                        <p>Having compulsive sexual thoughts or participating in compulsive sexual activity, alone or with others, despite harmful consequences.</p>
                                    </div>
                            </div>

                </div>

            </div>
        )
    }
}

export default TypesOfAddiction;